import { useCallback } from 'react';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { normalizeRawTimelineEvent } from '@/pageAI/services/caseTimeline';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';
import { timelineEventQuery } from '@/pageAI/api/timeline';

const fetchTimelineEvent = async (request: GraphQLRequestFunction, eventId: string | undefined) => {
  if (typeof eventId !== 'string') return Promise.resolve(null);

  const responseBody = await request(timelineEventQuery, { id: eventId });

  const timelineEvent = normalizeRawTimelineEvent(responseBody.clientTimelineEvent);

  return timelineEvent;
};

export const queryMap = {
  timelineEvent: {
    queryKey: (eventId?: string) => ['timeline-event', eventId],
    queryFn: fetchTimelineEvent,
  },
};

export type TimelineEventQuery = Awaited<ReturnType<typeof queryMap.timelineEvent.queryFn>>;

export const useTimelineEvent = () => {
  const { request } = useGraphQLRequest();

  const fetchTimelineEventFn = useCallback(
    (eventId: string) => queryMap.timelineEvent.queryFn(request, eventId),
    [request],
  );

  return {
    fetchTimelineEvent: fetchTimelineEventFn,
  };
};
