import { memo } from 'react';
import { Box, Flex, Loader } from '@mantine/core';

import { PDFViewer } from '../PDFViewer/PDFViewer';
import { ErrorPlaceholder } from '../../common/placeholders/ErrorPlaceholder';

interface PDFViewerContainerProps {
  status: 'loading' | 'error' | 'loaded';
  scale: number;
  pageNumber: string;
  draggingEnabled?: boolean;
  onDragEnd?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const PDFViewerContainerBase = ({
  status,
  scale,
  pageNumber,
  onDragEnd,
  draggingEnabled = true,
}: PDFViewerContainerProps) => {
  return (
    <Box id="viewerContainer" sx={{ position: 'relative' }}>
      <PDFViewer scale={scale} pageNumber={pageNumber} onDragEnd={onDragEnd} draggingEnabled={draggingEnabled} />

      {status === 'loading' && (
        <Flex align="center" justify="center" sx={{ height: '100%' }}>
          <Loader />
        </Flex>
      )}

      {status === 'error' && (
        <Flex align="center" justify="center" sx={{ height: '100%' }}>
          <ErrorPlaceholder
            title="File could not be loaded"
            description="Something prevented the file from being loaded. Please try again later."
          />
        </Flex>
      )}
    </Box>
  );
};

export const PDFViewerContainer = memo(PDFViewerContainerBase);
