import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { ActionIcon, Flex, LoadingOverlay, Text, Tooltip } from '@mantine/core';
import { IconChevronsRight } from '@tabler/icons-react';

import { AssetSidebarProps } from './AssetSidebar.types';
import { TextPreviewer, TextPreviewerRef } from '@/shared/components/preview/TextPreviewer';
import {
  FilePreviewTab,
  setFilePreviewCurrentPageIndex,
  setFilePreviewTextContent,
  setIsLoadingTextContent,
  useFilePreviewStore,
} from '@/shared/states/filePreview';
import { closeRightSidebar } from '@/shared/states/layout';
import { useGetOcrText } from '@/shared/hooks/files/useGetOcrText';
import { AssetType } from '@/shared/@types';
import { NotFoundPlaceholder } from '@/shared/components/common/placeholders/NotFoundPlaceholder';
import { PDFPreviewer } from '@/shared/components/pdf/PDFPreviewer';
import { formatDate, parseDateOnly } from '@/shared/utils/date';
import { AssetSidebarActionMenu } from '../AssetSidebarActionMenu';
import { AssetSidebarController } from '../AssetSidebarController';
import { AddOrRemoveFileFromConditionButton } from '@/pageAI/components/medicalConditions/errorCorrection/AddOrRemoveFileFromConditionButton';

const AssetSidebarBase: React.FC<AssetSidebarProps> = ({
  canClose = true,
  searchParamsDisabled = false,
  pdfDraggingEnabled = true,
}) => {
  const {
    asset,
    displayName,
    textContent,
    isLoadingTextContent,
    defaultTab,
    initialPageIndex,
    highlightedTexts,
    pagesToHighlight,
  } = useFilePreviewStore();
  const { getOcrText } = useGetOcrText();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const textPreviewerRef = useRef<TextPreviewerRef | null>(null);
  const [activeTab, setActiveTab] = useState<FilePreviewTab>(defaultTab);
  const [containerY, setContainerY] = useState(0);
  const currentPDFPageIndex = useRef(initialPageIndex);

  useEffect(() => {
    currentPDFPageIndex.current = initialPageIndex;
    setFilePreviewCurrentPageIndex(initialPageIndex);
  }, [initialPageIndex]);

  useEffect(() => {
    if (!containerRef.current) return;

    const { y } = containerRef.current.getBoundingClientRect();

    setContainerY(y);
  }, [containerRef]);

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  useEffect(() => {
    if (asset?.type !== AssetType.FILE || textContent || isLoadingTextContent) return;

    (async () => {
      setIsLoadingTextContent(true);

      const ocrText = await getOcrText(asset.id);

      setFilePreviewTextContent(ocrText);
      setIsLoadingTextContent(false);
    })();
  }, [asset, textContent, getOcrText, isLoadingTextContent]);

  const handleTabChange = useCallback((newTabValue: FilePreviewTab) => {
    setActiveTab(newTabValue);
  }, []);

  const renderTextPreviewer = () => {
    if (isLoadingTextContent) return <LoadingOverlay visible />;

    if (!textContent)
      return (
        <NotFoundPlaceholder
          action={null}
          description="The text content of this file does not exist. Either the file does not exist or it has not gone through the OCR process yet. If you think this is an error, please contact us."
        />
      );

    return (
      <TextPreviewer
        ref={textPreviewerRef}
        content={textContent}
        initialPageIndex={currentPDFPageIndex.current}
        paginated={!!textContent && !!textContent.length}
      />
    );
  };

  const handlePDFPageIndexChange = useCallback((pageIndex: number) => {
    currentPDFPageIndex.current = pageIndex;
    setFilePreviewCurrentPageIndex(pageIndex);
  }, []);

  const assetDisplayName = displayName || `${asset?.name} [${asset?.index}]`;
  const fullTextToDisplay = (
    <>
      {assetDisplayName}
      {asset?.fileReceiveDate && <>&nbsp;-&nbsp;Received {formatDate(asset.fileReceiveDate)}</>}
    </>
  );

  return (
    <Flex
      direction="column"
      sx={(theme) => ({
        height: '100%',
        background: theme.fn.lighten(theme.colors.gray[0], 0.5),
        position: 'relative',
        zIndex: 101,
      })}
      ref={containerRef}
    >
      <Flex align="center" justify="center" sx={{ width: '100%', position: 'relative' }} px="xs" mt="sm" gap="xs">
        <Flex
          align="center"
          justify="center"
          direction="column"
          sx={{ flex: 1, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          <Flex align="center" gap={4} sx={{ width: '100%' }} pl={canClose ? 6 : 0}>
            <Tooltip label={fullTextToDisplay} withinPortal withArrow>
              <Text
                fz="0.875rem"
                fw={600}
                color="dark.4"
                sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '80%' }}
                data-testid="file-sidebar-title"
              >
                {assetDisplayName}
              </Text>
            </Tooltip>

            {asset?.fileReceiveDate && (
              <Text
                fz="0.75rem"
                color="dark.4"
                mt={-4}
                mb={-4}
                sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
              >
                – Received {formatDate(parseDateOnly(asset.fileReceiveDate))}
              </Text>
            )}
          </Flex>
        </Flex>

        <Flex align="center" justify="flex-end" gap={4}>
          {asset && (
            <Flex align="center" justify="center" mr={2}>
              <AddOrRemoveFileFromConditionButton
                fileAsset={asset}
                color="gray.7"
                removeButtonColor="dark.4"
                variant="filled"
                removeButtonVariant="outline"
              />
            </Flex>
          )}

          <AssetSidebarActionMenu tab={activeTab} onTabChange={handleTabChange} />

          {canClose && (
            <Tooltip label="Collapse" withArrow withinPortal>
              <ActionIcon
                onClick={closeRightSidebar}
                color="gray.6"
                size="xs"
                pos="absolute"
                left={-10}
                variant="outline"
                sx={(theme) => ({
                  background: theme.colors.gray[0],
                  '&:hover': {
                    background: theme.colors.gray[1],
                  },
                })}
              >
                <IconChevronsRight size={16} />
              </ActionIcon>
            </Tooltip>
          )}
        </Flex>
      </Flex>

      <Flex sx={{ padding: '8px 0 0', height: `calc(100vh - 44px - ${containerY}px)` }} mt={4} pos="relative">
        {activeTab === FilePreviewTab.PDF && (
          <PDFPreviewer
            fileAsset={asset}
            fileUrl={asset?.url}
            initialPageIndex={initialPageIndex}
            highlightedTexts={highlightedTexts || []}
            highlightWithinPages={pagesToHighlight}
            onPageChange={handlePDFPageIndexChange}
            draggingEnabled={pdfDraggingEnabled}
          />
        )}

        {activeTab === FilePreviewTab.Text && (
          <Flex direction="column" sx={{ width: '100%' }}>
            <Flex
              sx={{
                width: '100%',
                flexGrow: 1,
                minHeight: 0,
                minWidth: 0,
                position: 'relative',
              }}
            >
              {renderTextPreviewer()}
            </Flex>
          </Flex>
        )}
      </Flex>

      {!searchParamsDisabled && <AssetSidebarController />}
    </Flex>
  );
};

export const AssetSidebar = memo(AssetSidebarBase);
