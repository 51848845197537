import { useCallback } from 'react';

import { Sentry } from '@/shared/plugins/sentry';
import { useAccessToken } from '../../auth/useAccessToken';
import { useLogout } from '../../auth/useLogout';
import { isNetworkError } from '@/shared/services/request';

export type FetchRequest = (url: string, options?: RequestInit) => Promise<Response>;

export const useFetchRequest = () => {
  const { logout } = useLogout();
  const { getAccessTokenSilently } = useAccessToken();

  const fetchRequest: FetchRequest = useCallback(
    async (url: string, options: RequestInit = {}) => {
      try {
        const token = await getAccessTokenSilently();

        options.headers = {
          ...options.headers,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        };

        const response = await fetch(url, options);

        return response;
      } catch (error) {
        const isExpiredToken = isNetworkError(error) && error.response.status === 401;

        if (!isExpiredToken) {
          Sentry.captureException(error);

          throw error;
        }

        logout();

        throw error;
      }
    },
    [getAccessTokenSilently, logout],
  );

  return { fetchRequest };
};
