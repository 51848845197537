import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { ActionIcon, Alert, Button, Flex, ScrollArea, Text, Tooltip } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IconAlertCircle, IconX } from '@tabler/icons-react';

import { CaseTimelineEditorProps } from './CaseTimelineEditor.types';
import { ConfidenceSelect } from '../../../summaries/ConfidenceSelect';
import { EventConfidenceLevel } from '@/pageAI/@types/summaries';
import { formatShortDate, isDateInFuture, parseDateOnly } from '@/shared/utils/date';
import { TimelineEditorGroup } from '../TimelineEditorGroup';
import { getDetailsAndDetailsTypes, getSummariesAndSummariesTypes } from '@/pageAI/services/caseTimeline';
import { DetailsType, SummariesType } from '@/pageAI/gql/graphql';

const CaseTimelineEditorBase = ({ item, onSubmit, onClose }: CaseTimelineEditorProps) => {
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const [date, setDate] = useState<Date | null>(() => parseDateOnly(item.date));
  const [dateError, setDateError] = useState<string | null>(null);
  const [confidenceLevel, setConfidenceLevel] = useState((item.confidenceLevel || 'low') as EventConfidenceLevel);
  const [eventSummariesMapping, setEventSummariesMapping] = useState(item.summariesByType);
  const [eventDetailsMapping, setEventDetailsMapping] = useState(item.detailsByType);

  const generationMatch = useRef(item.generation);

  const hasDateChanged = date && item.date !== formatShortDate(date);

  const handleSave = () => {
    if (!date) return;

    const { summaries, summariesTypes } = getSummariesAndSummariesTypes(eventSummariesMapping);
    const { details, detailsTypes } = getDetailsAndDetailsTypes(eventDetailsMapping);

    onSubmit({
      date: formatShortDate(date),
      confidenceLevel,
      summaries,
      summariesTypes,
      details,
      detailsTypes,
      generationMatch: generationMatch.current || 0,
    });

    onClose();
  };

  const handleChangeSummaryDataRow = useCallback((newContent: string, contentType: string, index: number) => {
    const summaryType = contentType as SummariesType;

    setEventSummariesMapping((prev) => {
      const newMapping = { ...prev };

      newMapping[summaryType]?.splice(index, 1, newContent);

      return newMapping;
    });
  }, []);

  const handleChangeDetailDataRow = useCallback((newData: string, contentType: string, index: number) => {
    const detailType = contentType as DetailsType;

    setEventDetailsMapping((prev) => {
      const newMapping = { ...prev };

      newMapping[detailType]?.splice(index, 1, newData);

      return newMapping;
    });
  }, []);

  const handleAddSummaryDataRow = useCallback((contentType: string) => {
    const summaryType = contentType as SummariesType;

    setEventSummariesMapping((prev) => {
      const draft = { ...prev };

      draft[summaryType] = [...(draft[summaryType] || []), ''];

      return draft;
    });
  }, []);

  const handleRemoveSummaryDataRow = useCallback((contentType: string, index: number) => {
    const summaryType = contentType as SummariesType;

    setEventSummariesMapping((prev) => {
      const draft = { ...prev };

      draft[summaryType] = draft[summaryType]?.filter((_, i) => i !== index);

      return draft;
    });
  }, []);

  const handleAddDetailDataRow = useCallback((contentType: string) => {
    const detailType = contentType as DetailsType;

    setEventDetailsMapping((prev) => {
      const draft = { ...prev };

      draft[detailType] = [...(draft[detailType] || []), ''];

      return draft;
    });
  }, []);

  const handleRemoveDetailDataRow = useCallback((contentType: string, index: number) => {
    const detailType = contentType as DetailsType;

    setEventDetailsMapping((prev) => {
      const draft = { ...prev };

      draft[detailType] = draft[detailType]?.filter((_, i) => i !== index);

      return draft;
    });
  }, []);

  const handleDateInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isDateInFuture(event.target.value)) {
      if (dateError) setDateError(null);

      return;
    }

    const initialValue = parseDateOnly(item.date);

    setDate(initialValue);
    setDateError('Date cannot be in the future');
  };

  const handleDateInputBlur = () => {
    setDateError(null);
  };

  useEffect(() => {
    const timelineScrollAreaViewport = document.querySelector(
      '#case-timeline-scroll-area .ghost-ScrollArea-viewport',
    ) as HTMLDivElement | null;
    const editorBodyScrollAreaViewport = scrollAreaRef.current?.querySelector(
      '.ghost-ScrollArea-viewport',
    ) as HTMLDivElement | null;

    if (!editorBodyScrollAreaViewport || !timelineScrollAreaViewport) return;

    const handleWheel = (event: WheelEvent) => {
      // Determine the scroll delta
      const delta = event.deltaY;

      // Check if the child element is at the top or bottom
      const atTop = editorBodyScrollAreaViewport.scrollTop === 0 && delta < 0;
      const atBottom =
        editorBodyScrollAreaViewport.scrollTop + editorBodyScrollAreaViewport.clientHeight >=
          editorBodyScrollAreaViewport.scrollHeight && delta > 0;

      if (atBottom || atTop) {
        // Scroll the container instead of the child
        timelineScrollAreaViewport.scrollBy({
          top: delta / 2,
          behavior: 'smooth',
        });
      }
    };

    editorBodyScrollAreaViewport.addEventListener('wheel', handleWheel);

    return () => {
      editorBodyScrollAreaViewport.removeEventListener('wheel', handleWheel);
    };
  }, [scrollAreaRef.current]);

  return (
    <Flex direction="column" gap="md">
      <Flex align="center" justify="space-between" px={16} pt={12} gap="xs">
        <Text fw={600} color="dark.4">
          Edit event
        </Text>

        <ActionIcon onClick={onClose}>
          <IconX size={16} />
        </ActionIcon>
      </Flex>

      <ScrollArea.Autosize mah="calc(100vh - 320px)" ref={scrollAreaRef}>
        <Flex direction="column" gap="xs">
          <Flex direction="column" gap="xs" pl={16} pr={24}>
            {hasDateChanged && (
              <Alert
                color="orange"
                variant="light"
                icon={<IconAlertCircle size={16} />}
                sx={(theme) => ({
                  maxWidth: 664,
                  border: `1px solid ${theme.colors.orange[5]}`,
                  '.ghost-Alert-icon': {
                    marginRight: 4,
                  },
                })}
              >
                <Text color="dark.6" fz="0.875rem">
                  Only changes to the date in this timeline event will reflect in the condition summary.
                </Text>
              </Alert>
            )}

            <Flex align="center" gap="xs" sx={{ '> *': { flex: '1 1' } }}>
              <Tooltip
                opened={!!dateError}
                label={
                  <Flex align="center" gap={4}>
                    <Flex
                      align="center"
                      justify="center"
                      sx={(theme) => ({
                        color: theme.colors.red[6],
                      })}
                    >
                      <IconAlertCircle size={16} />
                    </Flex>

                    <Text>{dateError}</Text>
                  </Flex>
                }
                withArrow
                withinPortal
                position="top"
              >
                <DateInput
                  label="Date"
                  value={date}
                  valueFormat="YYYY-MM-DD"
                  onChange={setDate}
                  maxDate={new Date()}
                  onInput={handleDateInput}
                  onBlur={handleDateInputBlur}
                  popoverProps={{
                    withinPortal: true,
                  }}
                />
              </Tooltip>

              <ConfidenceSelect value={confidenceLevel} onChange={setConfidenceLevel} />
            </Flex>

            <Flex direction="column" gap="xs" sx={{ width: '100%' }}>
              {Object.entries(eventSummariesMapping).map(([summaryType, summaries]) => {
                return (
                  <TimelineEditorGroup
                    key={summaryType}
                    contents={summaries || []}
                    contentType={summaryType}
                    onChange={handleChangeSummaryDataRow}
                    onAdd={handleAddSummaryDataRow}
                    onRemove={handleRemoveSummaryDataRow}
                  />
                );
              })}

              {Object.entries(eventDetailsMapping).map(([detailsType, details], index) => {
                return (
                  <TimelineEditorGroup
                    key={detailsType}
                    contents={details || []}
                    contentType={detailsType}
                    onChange={handleChangeDetailDataRow}
                    onAdd={handleAddDetailDataRow}
                    onRemove={handleRemoveDetailDataRow}
                    isFirstGroup={index === 0}
                  />
                );
              })}
            </Flex>
          </Flex>

          <Flex justify="flex-end" gap="xs" px={24} pb={16}>
            <Button variant="subtle" color="gray.7" onClick={onClose}>
              Cancel
            </Button>

            <Button onClick={handleSave}>Save</Button>
          </Flex>
        </Flex>
      </ScrollArea.Autosize>
    </Flex>
  );
};

export const CaseTimelineEditor = memo(CaseTimelineEditorBase);
