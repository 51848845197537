import { memo, useState } from 'react';
import { ActionIcon, Button, Flex, Text, Tooltip } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IconAlertCircle, IconX } from '@tabler/icons-react';

import { ConditionSummaryReferencedFileInfo } from '@/pageAI/gql/graphql';
import { formatShortDate, isDateInFuture, parseDateOnly } from '@/shared/utils/date';
import { useUpdateConditionEntry } from '@/pageAI/hooks/conditions/useUpdateConditionEntry';
import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { getConditionSummaryEntryDate } from '@/pageAI/services/medicalConditions';

interface EditConditionEntryPopoverProps {
  condition: MedicalConditionItem;
  entry: ConditionSummaryReferencedFileInfo;
  onClose: () => void;
  onFinish?: (updatedEntryIds: string[]) => void;
}

const EditConditionEntryPopoverBase = ({ condition, entry, onClose, onFinish }: EditConditionEntryPopoverProps) => {
  const { updateConditionEntry, isUpdating } = useUpdateConditionEntry();

  const [date, setDate] = useState<Date | null>(() => parseDateOnly(getConditionSummaryEntryDate(entry)));
  const [error, setError] = useState<string | null>(null);

  const handleSave = async () => {
    const response = await updateConditionEntry({
      conditionId: condition.id,
      itemId: entry.id,
      eventDate: date ? formatShortDate(date) : null,
    });

    onClose();

    if (!response) return;

    onFinish?.(response.updatedItemIds);
  };

  const handleDateChange = (value: Date | null) => {
    setDate(value);
  };

  const handleDateInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isDateInFuture(event.target.value)) {
      if (error) setError(null);

      return;
    }

    const initialValue = parseDateOnly(getConditionSummaryEntryDate(entry));

    setDate(initialValue);
    setError('Date cannot be in the future');
  };

  const handleDateInputBlur = () => {
    setError(null);
  };

  const handleDateInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSave();

      event.currentTarget.blur();
    }
  };

  return (
    <Flex direction="column" gap="xs">
      <Flex align="center" justify="space-between" px={16} pt={12} gap="xs">
        <Text fw={600} color="dark.4">
          Edit entry
        </Text>

        <ActionIcon onClick={onClose}>
          <IconX size={16} />
        </ActionIcon>
      </Flex>

      <Flex direction="column" gap="xs" sx={{ minWidth: 320 }}>
        <Flex direction="column" gap="xs" pl={16} pr={16}>
          <Flex align="center" gap="xs" sx={{ '> *': { flex: '1 1' } }}>
            <Tooltip
              opened={!!error}
              label={
                <Flex align="center" gap={4}>
                  <Flex
                    align="center"
                    justify="center"
                    sx={(theme) => ({
                      color: theme.colors.red[6],
                    })}
                  >
                    <IconAlertCircle size={16} />
                  </Flex>

                  <Text>{error}</Text>
                </Flex>
              }
              withArrow
              withinPortal
              position="top"
            >
              <DateInput
                label="Date"
                value={date}
                valueFormat="YYYY-MM-DD"
                onInput={handleDateInput}
                onChange={handleDateChange}
                onBlur={handleDateInputBlur}
                onKeyDown={handleDateInputKeyDown}
                disabled={isUpdating}
                maxDate={new Date()}
              />
            </Tooltip>
          </Flex>
        </Flex>

        <Flex justify="flex-end" gap="xs" px={16} pb={16} mt="xs">
          <Button variant="subtle" color="gray.7" onClick={onClose} disabled={isUpdating}>
            Cancel
          </Button>

          <Button onClick={handleSave} loading={isUpdating}>
            Save
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const EditConditionEntryPopover = memo(EditConditionEntryPopoverBase);
