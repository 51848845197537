import { memo } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { ActionIcon, Portal } from '@mantine/core';
import { IconMessage } from '@tabler/icons-react';

import { ChatLayout } from '../ChatLayout';
import { useAskPageFeatureFlag } from '@/pageAI/hooks/featureFlags/useAskPageFeatureFlag';

interface ChatBubbleProps {
  userId: string;
  organizationId: string;
}

export const ChatBubbleBase = ({ userId, organizationId }: ChatBubbleProps) => {
  const [opened, { toggle }] = useDisclosure(false);
  const { enabled } = useAskPageFeatureFlag();

  if (!enabled) return null;

  return (
    <Portal>
      <ActionIcon
        variant="filled"
        color="dark"
        size="lg"
        radius="xl"
        onClick={toggle}
        sx={(theme) => ({
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 1000,
          boxShadow: theme.shadows.lg,
        })}
      >
        <IconMessage size={20} />
      </ActionIcon>

      <ChatLayout opened={opened} userId={userId} organizationId={organizationId} onClose={() => toggle()} />
    </Portal>
  );
};

export const ChatBubble = memo(ChatBubbleBase);
