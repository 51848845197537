import { memo } from 'react';
import {
  Box,
  DefaultMantineColor,
  Flex,
  Loader,
  Spoiler,
  Sx,
  Text,
  Tooltip,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import { addNoteHasReferencesSelector, resetAddNoteState, useNotesStore } from '@/pageAI/states/notes';
import { UnderlinedTextButton } from '@/shared/designSystem/UnderlinedTextButton';
import { InlineEditor } from '@/shared/components/editor/InlineEditor';
import { FetchStatus } from '@/shared/@types';

interface NoteSnippetProps {
  imageUrl?: string | null;
  snippet?: string | null;
  sx?: Sx;
  removable?: boolean;
  isAdding?: boolean;
  isEditing?: boolean;
  fetchSnippetStatus?: FetchStatus;
  onImageRemove?: () => void;
  onSnippetRemove?: () => void;
  onSnippetChange?: (value: string) => void;
}

interface ImageSectionProps {
  imageUrl: string;
  isAdding?: boolean;
  isEditing?: boolean;
  onRemove?: () => void;
}

interface TextSectionProps {
  snippet?: string | null;
  isAdding?: boolean;
  isEditing?: boolean;
  fetchSnippetStatus: FetchStatus;
  onSnippetChange?: (value: string) => void;
  onRemove?: () => void;
}

interface ReferenceSectionProps {
  children: React.ReactNode;
  title: string;
  titleBackgroundColor?: string;
  color?: DefaultMantineColor;
  canRemove?: boolean;
  onRemove?: () => void;
}

const ReferenceSection = ({
  children,
  title,
  titleBackgroundColor,
  color = 'gray',
  canRemove = false,
  onRemove,
}: ReferenceSectionProps) => {
  return (
    <Flex
      direction="column"
      sx={(theme) => ({
        border: `1px solid ${theme.colors[color][3]}`,
        borderRadius: 4,
        background: theme.white,
        padding: 8,
      })}
      pos="relative"
      mt={12}
    >
      <Flex my={2} align="center" pos="absolute" top={-14} left={8}>
        <Flex
          align="center"
          gap={2}
          py={2}
          px={6}
          sx={(theme) => ({
            background: titleBackgroundColor || theme.colors[color][0],
            border: `1px solid ${theme.colors[color][3]}`,
            borderRadius: 4,
          })}
        >
          <Text fz="0.625rem" fw={500} color="dark.4">
            {title}
          </Text>

          {canRemove && onRemove ? (
            <Tooltip label="Remove" withinPortal withArrow>
              <UnstyledButton
                color="gray.7"
                onClick={onRemove}
                display="flex"
                sx={{ alignItems: 'center', justifyContent: 'center' }}
              >
                <IconX size={12} />
              </UnstyledButton>
            </Tooltip>
          ) : null}
        </Flex>
      </Flex>

      {children}
    </Flex>
  );
};

const ImageSection = ({ imageUrl, isAdding = false, isEditing = false, onRemove }: ImageSectionProps) => {
  return (
    <ReferenceSection title="Screenshot" canRemove={isAdding || isEditing} onRemove={onRemove} color="orange">
      <Flex
        align="center"
        justify="center"
        my={4}
        sx={{
          width: '100%',
          overflow: 'hidden',
          paddingTop: 4,
        }}
      >
        <img src={imageUrl} alt="Note screenshot" style={{ maxWidth: 480, maxHeight: 200 }} />
      </Flex>
    </ReferenceSection>
  );
};

const TextSection = ({
  snippet,
  isAdding,
  isEditing,
  fetchSnippetStatus,
  onSnippetChange,
  onRemove,
}: TextSectionProps) => {
  const theme = useMantineTheme();

  const isSnippetCleared = !snippet && [FetchStatus.INITIAL, FetchStatus.SUCCESS].includes(fetchSnippetStatus);

  if (isSnippetCleared) return null;

  const renderContent = () => {
    if (fetchSnippetStatus === FetchStatus.LOADING) {
      return (
        <Flex align="center" justify="center" gap={4} mt={-8}>
          <Loader size={12} />

          <Text>Extracting text from screenshot...</Text>
        </Flex>
      );
    }

    if (snippet) {
      return (
        <Spoiler
          maxHeight={35}
          showLabel="Show more"
          hideLabel="Show less"
          fz="0.75rem"
          className="normalized-markdown"
          sx={(theme) => ({
            cursor: 'text',
            width: '100%',
            '*:not(.ghost-Spoiler-control)': {
              fontSize: '0.75rem',
              wordBreak: 'break-word',
            },
            '.ghost-Spoiler-control': {
              color: theme.colors.dark[4],
              fontWeight: 600,
            },
            ul: {
              margin: 0,
            },
          })}
        >
          <InlineEditor data={snippet} onDataChange={onSnippetChange} readonly={!isEditing} />
        </Spoiler>
      );
    }

    return null;
  };

  return (
    <ReferenceSection
      title="Excerpt"
      canRemove={isAdding || isEditing}
      onRemove={onRemove}
      color="dark"
      titleBackgroundColor={theme.fn.lighten(theme.colors.dark[0], 0.7)}
    >
      <Box
        pos="relative"
        className="normalized-markdown"
        sx={(theme) => ({
          paddingTop: 8,
          whiteSpace: 'break-spaces',
          wordBreak: 'break-word',
          cursor: 'text',
          ul: { margin: 0 },
          '*': {
            fontSize: '0.75rem',
            lineHeight: 'normal',
          },
          '.ck.ck-content.ck-editor__editable': {
            minHeight: 16,
          },
        })}
      >
        {renderContent()}
      </Box>
    </ReferenceSection>
  );
};

const NoteFileSnippetBase = ({
  imageUrl,
  snippet,
  sx,
  removable = false,
  isAdding = false,
  isEditing = false,
  fetchSnippetStatus = FetchStatus.INITIAL,
  onImageRemove,
  onSnippetRemove,
  onSnippetChange,
}: NoteSnippetProps) => {
  const hasReferences = useNotesStore(addNoteHasReferencesSelector);
  const isPrinting = useNotesStore((state) => state.isPrinting);

  const clearReferencesSection = (
    <UnderlinedTextButton color="gray.7" sx={{ fontSize: '0.75rem' }} onClick={resetAddNoteState}>
      Clear references
    </UnderlinedTextButton>
  );

  if (snippet || imageUrl) {
    const content = (
      <Flex direction="column" gap="xs" sx={{ width: '100%' }}>
        {imageUrl && (
          <ImageSection imageUrl={imageUrl} isAdding={isAdding} isEditing={isEditing} onRemove={onImageRemove} />
        )}

        <TextSection
          snippet={snippet}
          isAdding={isAdding}
          isEditing={isEditing}
          fetchSnippetStatus={fetchSnippetStatus}
          onSnippetChange={onSnippetChange}
          onRemove={onSnippetRemove}
        />
      </Flex>
    );

    return (
      <Flex
        justify="center"
        pos="relative"
        direction="column"
        sx={(theme) => ({
          width: '100%',
          ...sx,
        })}
      >
        {content}

        {removable && (
          <Flex align="center" justify="end" sx={{ width: '100%' }} mt={8} gap={6}>
            {clearReferencesSection}
          </Flex>
        )}
      </Flex>
    );
  }

  if (hasReferences && !isPrinting && isAdding) {
    return (
      <Flex align="center" justify="end" sx={{ width: '100%' }} gap={6}>
        {clearReferencesSection}
      </Flex>
    );
  }

  return null;
};

export const NoteFileSnippet = memo(NoteFileSnippetBase);
