import { useQuery } from '@tanstack/react-query';

import { FetchRequest } from '@/shared/hooks/request/useFetchRequest';
import { useChatServerRequest } from '@/shared/hooks/request/useChatServerRequest';

export enum ChatMessageRole {
  Human = 'human',
  Bot = 'bot',
}

export interface ChatMessage {
  id: string;
  role: ChatMessageRole;
  message: string;
}

export interface ChatHistoryResponseBody {
  session_id: string;
  messages: ChatMessage[];
}

export const queryMap = {
  chatHistory: {
    queryKey: (userId: string, organizationId: string, clientId: string) => [
      'client',
      userId,
      organizationId,
      clientId,
    ],
    queryFn: async (request: FetchRequest, userId: string, organizationId: string, clientId: string) => {
      const response = await request(
        `/api/v1/chat_history?user_id=${userId}&org_id=${organizationId}&client_id=${clientId}`,
      );

      if (!response.ok) {
        throw new Error('Failed to fetch chat history');
      }

      const responseBody = (await response.json()) as ChatHistoryResponseBody;

      return responseBody;
    },
  },
};

export type ChatHistoryQuery = Awaited<ReturnType<typeof queryMap.chatHistory.queryFn>>;

interface UseChatHistoryProps {
  userId: string;
  organizationId: string;
  clientId: string;
}

export const useChatHistory = ({ userId, organizationId, clientId }: UseChatHistoryProps) => {
  const { request } = useChatServerRequest();

  const { data, isLoading, isError } = useQuery<ChatHistoryQuery>(
    queryMap.chatHistory.queryKey(userId, organizationId, clientId),
    () => queryMap.chatHistory.queryFn(request, userId, organizationId, clientId),
  );

  return { chatHistory: data, isLoading, isError };
};
