import { useCallback } from 'react';
import { pick } from 'lodash-es';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Client } from '@/pageAI/@types';
import { CaseTimelineEvent } from '@/pageAI/@types/summaries';
import { updateTimelineEvent } from '@/pageAI/services/caseTimeline';
import { UpdateClientTimelineEventInput, UpdateClientTimelineEventMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { updateClientTimelineEventMutation } from '@/pageAI/api';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { truthy } from '@/shared/utils/boolean';
import { ClientTimelineEventsQuery, queryMap as timelineEventQueryMap } from '../../clients/useClientTimelineEvents';
import { queryMap as conditionQueryMap } from '../../conditions/useCondition';

export const useUpdateTimelineEvent = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();
  const queryClient = useQueryClient();

  const updateTimelineEventLocally = useCallback(
    (client: Client, newTimelineEvent: CaseTimelineEvent) => {
      const queryKey = timelineEventQueryMap.client.timelineEvents.queryKey(client.id);

      const previousQuery = queryClient.getQueryData<ClientTimelineEventsQuery>(queryKey);

      queryClient.setQueryData<ClientTimelineEventsQuery>(queryKey, (timelineEventsQuery) => {
        if (!timelineEventsQuery) return timelineEventsQuery;

        return updateTimelineEvent(timelineEventsQuery?.filter(truthy) || [], newTimelineEvent);
      });

      return () => queryClient.setQueryData<ClientTimelineEventsQuery>(queryKey, previousQuery);
    },
    [queryClient],
  );

  const updateTimelineEventMutation = useMutation<
    UpdateClientTimelineEventMutation,
    Error,
    UpdateClientTimelineEventInput
  >({
    mutationFn: async (input) => {
      return request(updateClientTimelineEventMutation, { input });
    },
  });

  const updateTimelineEventOptimistically = useCallback(
    async (client: Client, newTimelineEvent: CaseTimelineEvent) => {
      let reverseUpdate = updateTimelineEventLocally(client, newTimelineEvent);

      const input: UpdateClientTimelineEventInput = {
        ...pick(newTimelineEvent, [
          '__typename',
          'id',
          'confidenceLevel',
          'date',
          'summaries',
          'summariesTypes',
          'details',
          'detailsTypes',
          'eventType',
          'verificationLevel',
        ]),
        generationMatch: newTimelineEvent.generation,
      };

      try {
        const { updateClientTimelineEvent } = await updateTimelineEventMutation.mutateAsync(input);

        reverseUpdate = updateTimelineEventLocally(client, {
          ...newTimelineEvent,
          generation: updateClientTimelineEvent.clientTimelineEvent.generation,
        });

        updateClientTimelineEvent.updatedMedicalConditionIds.forEach((conditionId) => {
          queryClient.refetchQueries({ queryKey: conditionQueryMap.condition.queryKey(conditionId) });
        });
      } catch (error) {
        reverseUpdate();

        notify('Error', 'Failed to update timeline event');
      }
    },
    [updateTimelineEventLocally, updateTimelineEventMutation, notify, queryClient],
  );

  return { updateTimelineEventLocally, updateTimelineEventOptimistically };
};
