import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { ActionIcon, Box, Flex, Paper, Text, Transition } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import { useSendChat } from '@/pageAI/hooks/chat/useChat';
import { useChatHistory } from '@/pageAI/hooks/chat/useChatHistory';
import { ChatMessages } from '../ChatMessages';
import { ChatInput } from '../ChatInput';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';

import PageLogoCircle from '@/pageAI/icons/page-logo-circle.svg?react';

interface ChatLayoutProps {
  opened?: boolean;
  userId: string;
  organizationId: string;
  onClose: () => void;
}

const ChatLayoutBase = ({ opened = false, userId, organizationId, onClose }: ChatLayoutProps) => {
  const { clientId } = useParams();

  const { notify } = useNotifications();
  const { sendMessageAsync, isLoading } = useSendChat();
  const { chatHistory, isLoading: isLoadingHistory } = useChatHistory({
    userId,
    organizationId,
    clientId: clientId || '',
  });

  const handleSendMessage = async (message: string) => {
    if (!clientId) return;

    try {
      await sendMessageAsync({
        user_id: userId,
        org_id: organizationId,
        client_id: clientId,
        user_message: message,
        session_id: chatHistory?.session_id,
      });
    } catch (error) {
      notify('Error', 'Failed to send message');
    }
  };

  const renderHeader = () => {
    return (
      <Flex
        justify="space-between"
        align="center"
        px="xs"
        py="xs"
        sx={(theme) => ({
          background: theme.colors.dark[5],
          svg: { circle: { display: 'none' } },
        })}
      >
        <Flex align="center" gap={6}>
          <PageLogoCircle width={24} height={24} />

          <Text fw={600} color="white">
            Ask Page
          </Text>
        </Flex>

        <ActionIcon onClick={onClose} size="xs">
          <IconX size={16} />
        </ActionIcon>
      </Flex>
    );
  };

  return (
    <Transition mounted={opened} transition="pop-bottom-right" duration={150}>
      {(styles) => (
        <Paper
          shadow="md"
          style={styles}
          sx={(theme) => ({
            position: 'fixed',
            bottom: 64,
            right: 20,
            width: 400,
            zIndex: 1000,
            border: `1px solid ${theme.colors.gray[2]}`,
          })}
        >
          <Flex direction="column" sx={{ height: '100%' }}>
            {renderHeader()}

            <ChatMessages messages={chatHistory?.messages || []} isWaitingForAnswer={isLoading} />

            <Box px="xs" pb="xs">
              <ChatInput onSendMessage={handleSendMessage} disabled={isLoading || isLoadingHistory} />
            </Box>
          </Flex>
        </Paper>
      )}
    </Transition>
  );
};

export const ChatLayout = memo(ChatLayoutBase);
