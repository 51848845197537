import { graphql } from '@/pageAI/gql';

export const fileBaseFullFieldsFragment = graphql(/* GraphQL */ `
  fragment File_BaseFullFields on File {
    id
    name
    mimeType
    url
    fileReceiveDate
    size
    viewerCanUpdate
    viewerCanDelete
    isImportant
    isCombinedCfile
    status {
      indexingStatus
      ocrStatus
      splitStatus
      transcriptionStatus
      uploadStatus
    }
    textExtractionProcessingDetails {
      processingProgress {
        partsProcessed
        partsTotal
      }
      processingStatus
    }
    metadata {
      totalPages
      pageAIMetadata {
        isVA
        indexNumber
        fileExtension
        vaFileTypeName
        eventTypeClassifications
        isVAFileTypeRecognized
        cFileTypes
        fileEventDate
      }
    }
    createdAt
    updatedAt
  }
`);

export const fileFullFieldsFragment = graphql(/* GraphQL */ `
  fragment File_FullFields on File {
    ...File_BaseFullFields
    duplicateOf {
      ...File_BaseFullFields
    }
  }
`);

export const updateFileMutation = graphql(/* GraphQL */ `
  mutation UpdateFile($input: UpdateFileInput!) {
    updateFile(input: $input) {
      ...File_FullFields
    }
  }
`);

export const markFileAsImportantMutation = graphql(/* GraphQL */ `
  mutation MarkFileAsImportant($input: MarkFileAsImportantInput!) {
    markFileAsImportant(input: $input) {
      id
    }
  }
`);

export const unmarkFileAsImportantMutation = graphql(/* GraphQL */ `
  mutation UnmarkFileAsImportant($input: UnmarkFileAsImportantInput!) {
    unmarkFileAsImportant(input: $input) {
      id
    }
  }
`);

export const ocrFilesMutation = graphql(/* GraphQL */ `
  mutation ocrFiles($input: OCRFilesInput!) {
    ocrFiles(input: $input)
  }
`);

export const getOcrTextQuery = graphql(/* GraphQL */ `
  query getOCRText($id: ID!) {
    getOCRText(id: $id) {
      id
      text
    }
  }
`);

export const fileQuery = graphql(/* GraphQL */ `
  query file($id: ID!) {
    file(id: $id) {
      ...File_FullFields
    }
  }
`);

export const generateUploadInitializationMutation = graphql(/* GraphQL */ `
  mutation generateUploadInitializationUrl($input: GenerateUploadInitializationUrlInput!) {
    generateUploadInitializationUrl(input: $input) {
      fileId
      url
    }
  }
`);

export const generateUploadPartMutation = graphql(/* GraphQL */ `
  mutation generateUploadUrl($input: GenerateUploadUrlInput!) {
    generateUploadUrl(input: $input) {
      fileId
      url
      partNumber
    }
  }
`);

export const generateUploadCompletionMutation = graphql(/* GraphQL */ `
  mutation generateUploadCompletionUrl($input: GenerateUploadCompletionUrlInput!) {
    generateUploadCompletionUrl(input: $input) {
      fileId
      url
    }
  }
`);

export const createCollectionMutation = graphql(/* GraphQL */ `
  mutation CreateCollection($name: String!) {
    createCollection(name: $name) {
      id
      name
    }
  }
`);

export const deleteFilesMutation = graphql(/* GraphQL */ `
  mutation DeleteFiles($ids: [ID!]!) {
    deleteFiles(ids: $ids)
  }
`);

export const fileGroupsQuery = graphql(/* GraphQL */ `
  query FileGroups($id: IDV2!) {
    organization(id: $id) {
      fileGroups(first: 9999) {
        nodes {
          id
          fileNames
          displayName
          color
          isDefault
        }
        pageInfo {
          startCursor
          hasNextPage
          hasPreviousPage
          endCursor
        }
        totalCount
      }
    }
  }
`);

export const createFileGroupMutation = graphql(/* GraphQL */ `
  mutation CreateFileGroup($input: CreateFileGroupInput!) {
    createFileGroup(input: $input) {
      id
      fileNames
      displayName
      color
      isDefault
    }
  }
`);

export const updateFileGroupMutation = graphql(/* GraphQL */ `
  mutation UpdateFileGroup($input: UpdateFileGroupInput!) {
    updateFileGroup(input: $input) {
      id
      fileNames
      displayName
      color
      isDefault
    }
  }
`);

export const deleteFileGroupMutation = graphql(/* GraphQL */ `
  mutation DeleteFileGroup($input: DeleteFileGroupInput!) {
    deleteFileGroup(input: $input) {
      deletedFileGroupId
    }
  }
`);

export const getFileIndexConfigQuery = graphql(/* GraphQL */ `
  query GetFileIndexConfig {
    getFileIndexConfig {
      cleanedFileName
      classification
    }
  }
`);

export const searchFilesQuery = graphql(/* GraphQL */ `
  query SearchFiles($clientIds: [ID!], $query: String!, $page: Int!, $fileNames: [String!]) {
    searchFiles(page: $page, clientIds: $clientIds, query: $query, fileNames: $fileNames) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        file {
          ...File_FullFields
        }
        pageNumber
        snippet
        score
        rerankMultiplier
      }
      totalCount
      totalPages
    }
  }
`);

export const clientMergedFileQuery = graphql(/* GraphQL */ `
  query ClientMergedFile($id: ID!) {
    client(id: $id) {
      mergedFile {
        downloadUrl
        fileIds
      }
    }
  }
`);

export const summarySearchResultQuery = graphql(/* GraphQL */ `
  query SummarySearchResult(
    $clientId: ID!
    $condition: String!
    $documents: [SummarySearchDocumentInput!]!
    $keyword: [String!]!
  ) {
    summarySearchResult(clientId: $clientId, condition: $condition, documents: $documents, keyword: $keyword) {
      content
      relevant
      documentId
      error
    }
  }
`);

export const confirmVAFileTypeMutation = graphql(/* GraphQL */ `
  mutation ConfirmVAFileType($fileIds: [ID!]!) {
    confirmVAFileType(fileIds: $fileIds) {
      id
    }
  }
`);

export const getDuplicatedFilesQuery = graphql(/* GraphQL */ `
  query GetDuplicatedFiles($clientId: ID!, $fileNames: [String!]!) {
    client(id: $clientId) {
      checkFileDuplicates(fileNames: $fileNames) {
        fileName
        fileNameFormat
        isDuplicate
        nextAction
      }
    }
  }
`);

export const updateFileMetadataFromFileNameMutation = graphql(/* GraphQL */ `
  mutation UpdateFileMetadataFromFileName($input: UpdateFileMetadataFromFileNameInput!) {
    updateFileMetadataFromFileName(input: $input) {
      name
    }
  }
`);
