import { memo, useMemo } from 'react';
import { Box, Button, Flex, LoadingOverlay } from '@mantine/core';
import { IconMessagePlus } from '@tabler/icons-react';

import { useConversations } from '@/shared/hooks/oldChat/useConversations';
import { useAppNavigate } from '@/shared/hooks/routes/useAppNavigate';
import { groupObjectsByDate } from '@/shared/utils/date';
import { ConversationGroup } from '../ConversationGroup';
import { ConversationSidebarProps } from './ConversationSidebar.types';

const ConversationSidebarBase = ({ topAction, width = 280, conversationType }: ConversationSidebarProps) => {
  const { navigateToChat } = useAppNavigate();
  const { conversations, isLoading } = useConversations();

  const conversationsGroupedByDate = useMemo(() => groupObjectsByDate(conversations), [conversations]);

  return (
    <Box
      sx={(theme) => ({
        height: '100%',
        padding: 16,
        width,
        position: 'relative',
        backgroundColor: theme.colors.gray[1],
        borderRight: `1px solid ${theme.colors.gray[3]}`,
      })}
    >
      <LoadingOverlay visible={isLoading} />

      {topAction ?? (
        <Button
          sx={{ width: '100%' }}
          leftIcon={<IconMessagePlus size={16} />}
          variant="filled"
          onClick={() => navigateToChat()}
        >
          New conversation
        </Button>
      )}

      {!isLoading && (
        <Flex direction="column" gap="lg" mt="lg">
          <ConversationGroup conversations={conversationsGroupedByDate.previous30DaysGroup} title="Last 30 Days" />

          <ConversationGroup conversations={conversationsGroupedByDate.twoMonthsAgoGroup} title="Last 2 Months" />

          <ConversationGroup conversations={conversationsGroupedByDate.olderGroup} title="Older" />
        </Flex>
      )}
    </Box>
  );
};

export const ConversationSidebar = memo(ConversationSidebarBase);
