import { useEffect } from 'react';

import { ConversationQuery } from '@/pageAI/gql/graphql';
import { resetChatContent, useChatStore } from '@/shared/states/chat';
import { useSendMessage } from '@/shared/hooks/oldChat/useSendMessage';
import { useGenerateConversationName } from '@/shared/hooks/oldChat/useGenerateConversationName';
import { useUpdateConversation } from '@/shared/hooks/oldChat/useUpdateConversation';

export const useEnsureSendMessage = (
  conversation?: ConversationQuery['conversation'],
  upsertConversationMessage?: (message: ConversationQuery['conversation']['messages']['nodes'][0]) => void,
) => {
  const { chatContent: initialChatContent, attachmentIds: initialAttachmentIds, retrieverType } = useChatStore();
  const { sendMessage } = useSendMessage();
  const { generateConversationName } = useGenerateConversationName();
  const { updateConversation } = useUpdateConversation();

  useEffect(() => {
    if (!conversation || !initialChatContent || conversation.messages.nodes.length > 0) return;

    sendMessage(
      {
        conversationId: conversation.id,
        content: initialChatContent,
        attachments: initialAttachmentIds?.map((attachmentId) => ({ fileId: attachmentId })),
        retrieverType,
      },
      {
        onSuccess: async (data) => {
          upsertConversationMessage?.(data.sendMessage);

          const response = await generateConversationName({ conversationId: conversation.id });

          const conversationName = response.generateConversationName;

          updateConversation({ id: conversation.id, name: conversationName });
        },
      },
    );

    resetChatContent();
  }, [
    retrieverType,
    initialChatContent,
    initialAttachmentIds,
    conversation,
    sendMessage,
    upsertConversationMessage,
    generateConversationName,
    updateConversation,
  ]);
};
