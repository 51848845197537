import { useState, useRef } from 'react';

import { isSelectingText } from '@/shared/lib/dom';

export type SelectionBoxState = {
  x: number;
  y: number;
  width: number;
  height: number;
} | null;

export function useSelectionBox() {
  const [isDragging, setIsDragging] = useState(false);
  const [box, setBox] = useState<SelectionBoxState>(null);
  const startCoords = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  const selectionThreshold = 5; // Minimum distance to trigger selection box

  const onMouseDown = (e: MouseEvent | React.MouseEvent, container: HTMLElement) => {
    if (isSelectingText(e)) return;

    const containerRect = container.getBoundingClientRect();

    const startX = e.clientX - containerRect.left;
    const startY = e.clientY - containerRect.top;

    startCoords.current = { x: startX, y: startY };
    setBox({
      x: startX,
      y: startY,
      width: 0,
      height: 0,
    });
    setIsDragging(false); // Reset dragging state
  };

  const onMouseMove = (e: MouseEvent | React.MouseEvent, container: HTMLElement) => {
    if (!box) return;

    const containerRect = container.getBoundingClientRect();

    const currentX = e.clientX - containerRect.left;
    const currentY = e.clientY - containerRect.top;

    // Check if the mouse is outside the container
    if (currentX < 0 || currentY < 0 || currentX > containerRect.width || currentY > containerRect.height) {
      return setIsDragging(false);
    }

    const startX = startCoords.current.x;
    const startY = startCoords.current.y;

    const deltaX = currentX - startX;
    const deltaY = currentY - startY;

    if (!isDragging && (Math.abs(deltaX) > selectionThreshold || Math.abs(deltaY) > selectionThreshold)) {
      setIsDragging(true);
    }

    if (isDragging) {
      setBox({
        x: Math.min(startX, currentX),
        y: Math.min(startY, currentY),
        width: Math.abs(deltaX),
        height: Math.abs(deltaY),
      });
    }
  };

  const onMouseUp = () => {
    setIsDragging(false);
    setBox(null); // Clear the selection box after the drag

    setTimeout(() => window.getSelection()?.removeAllRanges());
  };

  return { isDragging, box, onMouseDown, onMouseMove, onMouseUp };
}
