import React from 'react';
import { Box } from '@mantine/core';

import { SelectionBoxState } from './SelectionBox.utils';

interface SelectionBoxProps {
  box: SelectionBoxState | undefined;
  lighter?: boolean;
}

const SelectionBox: React.FC<SelectionBoxProps> = ({ box, lighter = false }) => {
  if (!box) return null;

  return (
    <Box
      sx={(theme) => ({
        position: 'absolute',
        left: `${box.x}px`,
        top: `${box.y}px`,
        width: `${box.width}px`,
        height: `${box.height}px`,
        backgroundColor: lighter
          ? theme.fn.rgba(theme.colors.orange[2], 0.2)
          : theme.fn.rgba(theme.colors.blue[5], 0.2),
        border: `1px solid ${
          lighter ? theme.fn.rgba(theme.colors.orange[5], 0.5) : theme.fn.rgba(theme.colors.blue[5], 0.8)
        }`,
        pointerEvents: 'none',
      })}
    />
  );
};

export default SelectionBox;
