import { memo } from 'react';
import { Alert, Container, Flex, Text } from '@mantine/core';
import { IconInfoCircle, IconMicroscope, IconZoomScan } from '@tabler/icons-react';

import { ChatBox } from '@/shared/components/oldChat/ChatBox';
import { Panel } from '@/pageAI/designSystem/Panel';
import { ActionPanel } from '@/pageAI/designSystem/ActionPanel';
import { useResearchType } from '@/pageAI/hooks/research/useResearchType';
import { ResearchType } from '@/pageAI/@types';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { getClientDisplayName } from '@/pageAI/services/clients';

const ResearchLayoutBase = () => {
  const [researchType, setResearchType] = useResearchType();
  const selectedClient = useSelectedClient();

  return (
    <Container size="xl" p="md">
      <Alert icon={<IconInfoCircle />} variant="outline">
        This feature is currently in beta – output could be unstable or incorrect. Please verify results for accuracy.
      </Alert>

      <Flex align="center" justify="center" sx={{ paddingTop: 40 }}>
        <Flex direction="column" justify="center" sx={{ width: '100%', height: '100%' }} gap="md">
          <Panel sx={{ padding: '80px 24px 24px 24px' }}>
            <Flex direction="column" align="center" gap="lg" sx={{ width: '100%' }}>
              <Flex direction="column" align="center" sx={{ width: '100%' }}>
                <Flex align="center" justify="center">
                  {researchType === ResearchType.RESEARCH ? <IconMicroscope size={80} /> : <IconZoomScan size={80} />}
                </Flex>

                <Text fz="1.25rem" fw={600} color="dark.5" align="center" mt={4} mb={4}>
                  {researchType === ResearchType.RESEARCH ? 'Conduct Medical Research' : 'Perform Client Q&A'}
                </Text>

                {selectedClient && (
                  <Text fz="0.875rem" color="dark.3" align="center">
                    for <strong>{getClientDisplayName(selectedClient)}</strong>
                  </Text>
                )}
              </Flex>

              <Flex sx={{ flexGrow: 1, height: 40 }} />

              <ChatBox
                compact
                shadow="none"
                hideRetrieverTypeSwitch
                sx={{ width: '100%' }}
                placeholder={
                  selectedClient || researchType !== ResearchType.INVESTIGATE
                    ? 'Enter your question...'
                    : 'Select a client and enter your question...'
                }
                disabled={researchType === ResearchType.INVESTIGATE && !selectedClient}
              />
            </Flex>
          </Panel>

          <Flex gap="md" sx={{ a: { width: '100%' } }}>
            <ActionPanel
              label="Conduct medical research"
              subLabel="Determine service connected conditions"
              variant="light"
              url={`/chat?type=${ResearchType.RESEARCH}`}
              sx={{ gap: 0, flexGrow: 1 }}
            />

            <ActionPanel
              label="Perform client Q&A"
              subLabel="Ask anything about a specific client"
              variant="light"
              url={`/chat?type=${ResearchType.INVESTIGATE}`}
              sx={{ gap: 0, flexGrow: 1 }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};

export const ResearchLayout = memo(ResearchLayoutBase);
