import { memo, useRef } from 'react';
import { Flex, Text } from '@mantine/core';

import { UserAvatar } from '@/shared/components/users/UserAvatar';
import { ChatMessage } from '@/pageAI/hooks/chat/useChatHistory';

interface UserMessageProps {
  message: ChatMessage;
}

const UserMessageBase = ({ message }: UserMessageProps) => {
  const messageContentElementRef = useRef<HTMLDivElement | null>(null);

  return (
    <Flex
      direction="column"
      gap={4}
      className="chat-user-message"
      sx={{
        '&:hover': {
          '.chat-user-message__actions': { opacity: 1, pointerEvents: 'all' },
        },
      }}
    >
      <Flex align="center" gap="xs">
        <UserAvatar />

        <Text
          ref={messageContentElementRef}
          color="dark"
          fz="0.875rem"
          sx={{ outline: 'none !important', minWidth: '100%', minHeight: 22 }}
        >
          {message.message}
        </Text>
      </Flex>
    </Flex>
  );
};

export const UserMessage = memo(UserMessageBase);
