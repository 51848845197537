import { graphql } from '../gql';

export const timelineEventFragment = graphql(/* GraphQL */ `
  fragment ClientTimelineEvent_FullFields on ClientTimelineEvent {
    id
    eventType
    summaries
    summariesTypes
    details
    detailsTypes
    date
    duplicated
    confidenceLevel
    reference
    referencedText
    verificationLevel
    bookmarked
    generation
    metadata {
      key
      value
    }
    viewerCanBookmark
    viewerCanComment
    viewerCanDelete
    viewerCanUnbookmark
    viewerCanUpdate
    viewerCanHide
    viewerCanUnhide
    createdAt
    updatedAt
  }
`);

export const timelineEventQuery = graphql(/* GraphQL */ `
  query ClientTimelineEvent($id: IDV2!) {
    clientTimelineEvent(id: $id) {
      ...ClientTimelineEvent_FullFields
    }
  }
`);
