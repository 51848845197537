import { create } from 'zustand';

import { CaseTimelineEvent, SimpleCondition } from '@/pageAI/@types/summaries';
import { User } from '@/pageAI/gql/graphql';
import { useLayoutStore } from '@/shared/states/layout';
import { useFilePreviewStore } from '@/shared/states/filePreview';
import { FetchStatus, FileAsset } from '@/shared/@types';
import { useUnifiedStore } from '../unified';

export interface NotesState {
  isPrinting: boolean;
  fetchSnippetStatus: FetchStatus;
  conditionFilter: SimpleCondition | null;
  authorFilter: User | null;
  fileSnippet: string;
  highlightedReference: string | null;
  isFullscreen: boolean;
  isSnippetMarkdown: boolean;
  conditionEntryKey: string;
  timelineEvent: CaseTimelineEvent | null;
  fileAsset: FileAsset | null;
  condition: SimpleCondition | null;
  imageUrl: string | null;
  imagePosition: { x: number; y: number; width: number; height: number } | null;
  computed: {
    hasReferences: boolean;
  };
}

export const useNotesStore = create<NotesState>((set, get) => ({
  isPrinting: false,
  fetchSnippetStatus: FetchStatus.INITIAL,
  conditionFilter: null,
  authorFilter: null,
  fileSnippet: '',
  highlightedReference: null,
  isFullscreen: false,
  isSnippetMarkdown: false,
  conditionEntryKey: '',
  timelineEvent: null,
  fileAsset: null,
  condition: null,
  imageUrl: null,
  imagePosition: null,
  computed: {
    get hasReferences() {
      const { fileAsset, timelineEvent, conditionEntryKey, condition } = get();

      return !!fileAsset || !!timelineEvent || !!conditionEntryKey || !!condition;
    },
  },
}));

export const addNoteHasReferencesSelector = (state: NotesState) => state.computed.hasReferences;

export const setConditionFilter = (condition: SimpleCondition | null) => {
  useNotesStore.setState({ conditionFilter: condition });
};

export const setAuthorFilter = (author: User | null) => {
  useNotesStore.setState({ authorFilter: author });
};

export const setNoteFileSnippet = (snippet: string, isMarkdown = false) => {
  useNotesStore.setState({ fileSnippet: snippet, isSnippetMarkdown: isMarkdown });
};

export const setHighlightedReference = (reference: string | null) => {
  useNotesStore.setState({ highlightedReference: reference });
};

export const setIsNotepadFullscreen = (isFullscreen: boolean) => {
  useNotesStore.setState({ isFullscreen });
};

export const toggleNotepadFullscreen = () => {
  useNotesStore.setState((state) => ({ isFullscreen: !state.isFullscreen }));
};

export const setNoteConditionEntryKey = (key: string) => {
  useNotesStore.setState({ conditionEntryKey: key });
};

export const setNoteCondition = (condition: SimpleCondition | null) => {
  useNotesStore.setState({ condition });
};

export const setNoteTimelineEvent = (event: CaseTimelineEvent | null) => {
  useNotesStore.setState({ timelineEvent: event });
};

export const setNoteFileAsset = (fileAsset: FileAsset | null) => {
  useNotesStore.setState({ fileAsset });
};

export const setIsPrintingNotes = (isPrinting: boolean) => {
  useNotesStore.setState({ isPrinting });
};

export const setNoteImageUrl = (
  url: string | null,
  rect: { x: number; y: number; width: number; height: number } | null = null,
) => {
  useNotesStore.setState({ imageUrl: url, imagePosition: rect });
};

export const setNoteImagePosition = (position: { x: number; y: number; width: number; height: number } | null) => {
  useNotesStore.setState({ imagePosition: position });
};

export const setFetchSnippetStatus = (fetchSnippetStatus: FetchStatus) => {
  useNotesStore.setState({ fetchSnippetStatus });
};

export const resetAddNoteState = () => {
  setFetchSnippetStatus(FetchStatus.INITIAL);
  setNoteFileSnippet('', false);
  setNoteCondition(null);
  setNoteConditionEntryKey('');
  setNoteTimelineEvent(null);
  setNoteFileAsset(null);
  setNoteImageUrl(null);
};

export const isNoteStateEmpty = () => {
  const { fileSnippet, condition, conditionEntryKey, timelineEvent, fileAsset, imageUrl } = useNotesStore.getState();

  return !fileSnippet && !condition && !conditionEntryKey && !timelineEvent && !fileAsset && !imageUrl;
};

useLayoutStore.subscribe((state, prevState) => {
  if (prevState.isRightSidebarOpened && !state.isRightSidebarOpened) {
    resetAddNoteState();
  }
});

useFilePreviewStore.subscribe((state, prevState) => {
  if (state.asset?.id !== prevState.asset?.id) {
    resetAddNoteState();
  }
});

useFilePreviewStore.subscribe((state, prevState) => {
  if (state.asset && state.asset.id !== prevState.asset?.id) {
    setNoteFileAsset(state.asset);
  }
});

useUnifiedStore.subscribe((state, prevState) => {
  if (state.selectedSimpleCondition && state.selectedSimpleCondition.id !== prevState.selectedSimpleCondition?.id) {
    setNoteCondition(state.selectedSimpleCondition);
  }
});
