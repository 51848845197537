import { useCallback } from 'react';
import urlJoin from 'url-join';

import { FetchRequest, useFetchRequest } from '../useFetchRequest';
import { CHAT_SERVER_URL } from '@/shared/config';

export const useChatServerRequest = () => {
  const { fetchRequest } = useFetchRequest();

  const request: FetchRequest = useCallback(
    async (apiRoute: string, options?: RequestInit) => {
      return fetchRequest(urlJoin(CHAT_SERVER_URL, apiRoute), options);
    },
    [fetchRequest],
  );

  return { request };
};
