export interface RectBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

/**
 * Maps coordinates from the viewer space to the PDF canvas space
 * @param box The selection box coordinates in viewer space
 * @param container The viewer container
 * @param canvas The PDF canvas element
 * @returns The mapped coordinates in PDF canvas space
 */
export const mapViewerCoordinatesToCanvas = (
  box: RectBox,
  container: HTMLDivElement,
  canvas: HTMLCanvasElement,
): RectBox => {
  const containerRect = container.getBoundingClientRect();
  const canvasRect = canvas.getBoundingClientRect();

  const xOffset = containerRect.left - canvasRect.left;
  const yOffset = containerRect.top - canvasRect.top;

  const canvasRescaleFactorX = canvas.width / parseFloat(getComputedStyle(canvas).width);
  const canvasRescaleFactorY = canvas.height / parseFloat(getComputedStyle(canvas).height);

  return {
    x: (box.x + xOffset) * canvasRescaleFactorX,
    y: (box.y + yOffset) * canvasRescaleFactorY,
    width: box.width * canvasRescaleFactorX,
    height: box.height * canvasRescaleFactorY,
  };
};
