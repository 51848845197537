import { memo, useCallback, useMemo, useState } from 'react';
import { Box, Button, Flex, Modal, Switch, Text } from '@mantine/core';

import { ConditionViewModeTabs } from '../../filters/ConditionViewModeTabs';
import { ShowNullFieldsFilter } from '../../filters/ShowNullFieldsFilter';
import { conditionsSelector, summaryTabViewModeSelector, useUnifiedStore } from '@/pageAI/states/unified';
import { MedicalConditionMenu } from '../../MedicalConditionMenu';
import { SimpleCondition } from '@/pageAI/@types/summaries';
import {
  setIsHrefActive,
  useSummaryFileReferenceBadgeState,
} from '@/pageAI/components/summaries/SummaryFileReferenceBadge';
import { FileUnreadableQuickAlert } from '@/pageAI/components/files/alerts/FileUnreadableQuickAlert';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { LastExportJob } from '@/pageAI/components/export/LastExportJob';
import { ClientExportJobType } from '@/pageAI/gql/graphql';
import { isArchivedCondition } from '@/pageAI/services/medicalConditions';

interface ExportConditionsModalProps {
  opened?: boolean;
  onClose: () => void;
  selectedConditionIds: string[];
  onSelect?: (selectedConditionIds: string[]) => void;
  onExport?: (withCFile?: boolean) => void;
}

const ExportConditionsModalBase = ({
  opened = false,
  selectedConditionIds,
  onSelect,
  onClose,
  onExport,
}: ExportConditionsModalProps) => {
  const client = useSelectedClient(true);

  const [isExporting, setIsExporting] = useState(false);
  const viewMode = useUnifiedStore(summaryTabViewModeSelector);
  const conditions = useUnifiedStore(conditionsSelector);
  const isHrefActive = useSummaryFileReferenceBadgeState((state) => state.isHrefActive);

  const filteredConditions = useMemo(
    () => conditions.filter((condition) => !isArchivedCondition(condition)),
    [conditions],
  );

  const handleExportWithMergedFile = async () => {
    handleExport(true);
  };

  const handleExport = (withCFile?: boolean) => {
    setIsExporting(true);

    onExport?.(withCFile);

    setIsExporting(false);
  };

  const handleSelectMultiple = useCallback(
    (conditions: SimpleCondition[], unselect = false) => {
      const conditionIds = conditions.map((condition) => condition.id);

      if (unselect) {
        return onSelect?.(selectedConditionIds.filter((id) => !conditionIds.includes(id)));
      }

      onSelect?.([...selectedConditionIds, ...conditionIds]);
    },
    [onSelect, selectedConditionIds],
  );

  const handleSelectSingle = useCallback(
    (condition: SimpleCondition) => {
      const conditionId = condition.id;

      onSelect?.(
        selectedConditionIds.includes(conditionId)
          ? selectedConditionIds.filter((id) => id !== conditionId)
          : [...selectedConditionIds, conditionId],
      );
    },
    [onSelect, selectedConditionIds],
  );

  const renderModalContent = () => {
    return (
      <Flex direction="column" gap="md">
        <Flex direction="column">
          <LastExportJob client={client} type={ClientExportJobType.ConditionSummary} />

          <Text fz="0.875rem" fw={500} color="dark" mt="xs" mb={4}>
            Export settings
          </Text>

          <Flex direction="column" gap="xs">
            <Flex align="center" gap="xs">
              <Flex align="center">
                <ConditionViewModeTabs />
              </Flex>

              {viewMode === 'Table View' && <ShowNullFieldsFilter alwaysShow />}
            </Flex>

            <Switch
              checked={isHrefActive}
              onChange={(event) => setIsHrefActive(event.currentTarget.checked)}
              label="Show references with links"
            />
          </Flex>

          <Text fz="0.875rem" fw={500} color="dark" mt="xs" mb={4}>
            Conditions
          </Text>

          <Box
            sx={(theme) => ({
              border: `1px solid ${theme.colors.gray[3]}`,
              borderRadius: 4,
              'div.condition-item': {
                width: 720,
              },
            })}
          >
            <MedicalConditionMenu
              medicalConditions={filteredConditions}
              maxHeight="424px"
              checkboxMode
              selected={selectedConditionIds}
              onSelect={handleSelectSingle}
              onSelectMultiple={handleSelectMultiple}
            />
          </Box>
        </Flex>

        <FileUnreadableQuickAlert client={client} isExport />

        <Flex align="center" justify="end" gap="xs">
          <Button onClick={onClose} color="gray.8" variant="subtle">
            Close
          </Button>

          <Button variant="outline" onClick={handleExportWithMergedFile} disabled={isExporting}>
            Export with merged file
          </Button>

          <Button color="red" onClick={() => handleExport()} disabled={isExporting}>
            {isExporting ? 'Exporting...' : 'Export'}
          </Button>
        </Flex>
      </Flex>
    );
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Export condition summary" size={800}>
      {renderModalContent()}
    </Modal>
  );
};

export const ExportConditionsModal = memo(ExportConditionsModalBase);
