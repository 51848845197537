import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { ConversationLayout } from '@/shared/components/oldChat/ConversationLayout';
import { useClientFileAnchorReference } from '@/pageAI/hooks/files/useClientFileAnchorReference';
import { useClient } from '@/pageAI/hooks/clients/useClient';
import { useConversation } from '@/shared/hooks/oldChat/useConversation';
import { setMessageRetrieverType } from '@/shared/states/chat';
import { setSelectedClient } from '@/pageAI/states/client';
import { MessageRetrieverType } from '@/pageAI/gql/graphql';
import { useResearchType } from '@/pageAI/hooks/research/useResearchType';
import { ResearchType } from '@/pageAI/@types';
import { withPageWrapper } from '@/shared/hocs/withPageWrapper';

const ConversationPageBase = () => {
  const { conversationId } = useParams();
  const {
    conversation,
    isLoading: isLoadingConversation,
    isError: isErrorConversation,
    upsertConversationMessage,
  } = useConversation(conversationId);
  const [, setResearchType] = useResearchType();
  const hasSetResearchType = useRef(false);

  const selectedClient = conversation?.client;
  const { client, isLoading: isLoadingClient, isError: isErrorClient } = useClient({ clientId: selectedClient?.id });
  useClientFileAnchorReference(client);

  const isLoading = isLoadingConversation || isLoadingClient;
  const isError = isErrorConversation || isErrorClient;

  useEffect(() => {
    const retrieverType = conversation?.messages?.nodes[0]?.retrieverType;

    if (retrieverType) {
      if (retrieverType === MessageRetrieverType.Docs && !hasSetResearchType.current) {
        setResearchType(ResearchType.INVESTIGATE);
        hasSetResearchType.current = true;
      }

      setMessageRetrieverType(retrieverType);
    }

    if (conversation?.client) {
      setSelectedClient(conversation.client);
    }
  }, [conversation, setResearchType]);

  return (
    <ConversationLayout
      conversation={conversation}
      isLoading={isLoading}
      isError={isError}
      upsertConversationMessage={upsertConversationMessage}
      sx={{ paddingTop: 16, paddingBottom: 160 }}
      minimalChatBox
    />
  );
};

export const ConversationPage = withPageWrapper(ConversationPageBase);
