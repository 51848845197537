import { memo, useMemo } from 'react';
import { ActionIcon, Flex, Popover, Select, Text, TextInput, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconActivityHeartbeat,
  IconFilter,
  IconFilterFilled,
  IconSelector,
  IconUser,
  IconX,
} from '@tabler/icons-react';

import { truthy } from '@/shared/utils/boolean';
import { setAuthorFilter, setConditionFilter, useNotesStore } from '@/pageAI/states/notes';
import { Note_FullFieldsFragment } from '@/pageAI/gql/graphql';
import { ConditionDropdown } from '../../medicalConditions/ConditionDropdown';
import { getAllAuthorsFromNotes } from '@/pageAI/services/notes';
import { getUserDisplayName } from '@/pageAI/services/users';

export interface NoteFiltersProps {
  notes: Note_FullFieldsFragment[];
}

const NoteFiltersBase = ({ notes }: NoteFiltersProps) => {
  const conditionFilter = useNotesStore((state) => state.conditionFilter);
  const authorFilter = useNotesStore((state) => state.authorFilter);

  const [conditionDropdownOpened, { toggle: toggleConditionDropdown, close: closeConditionDropdown }] = useDisclosure();

  const authors = useMemo(() => getAllAuthorsFromNotes(notes), [notes]);

  const authorOptions = useMemo(() => {
    return authors.map((author) => ({
      value: author.id,
      label: getUserDisplayName(author),
    }));
  }, [authors]);

  const handleChangeAuthor = (authorId: string) => {
    if (authorId === 'Anyone') {
      setAuthorFilter(null);
      return;
    }

    const author = authors.find((author) => author.id === authorId);

    setAuthorFilter(author || null);
  };

  const hasFilters = conditionFilter || authorFilter;

  const filterIcon = (
    <Flex sx={{ position: 'relative' }} align="center" justify="center">
      {hasFilters ? <IconFilterFilled size={16} /> : <IconFilter size={16} />}

      {hasFilters ? (
        <Flex
          align="center"
          justify="center"
          sx={(theme) => ({
            position: 'absolute',
            right: -4,
            top: -4,
            width: 12,
            height: 12,
            background: theme.colors.red[6],
            borderRadius: '50%',
          })}
        >
          <Text color="white" fz="0.5rem" fw={600}>
            {[conditionFilter, authorFilter].filter(truthy).length}
          </Text>
        </Flex>
      ) : null}
    </Flex>
  );

  return (
    <Popover withinPortal shadow="sm" keepMounted width={320} closeOnClickOutside={!conditionDropdownOpened}>
      <Popover.Target>
        <Tooltip label="Filter" withArrow withinPortal>
          <ActionIcon color="gray" className="unprintable">
            {filterIcon}
          </ActionIcon>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown>
        <Flex direction="column" gap="sm">
          <Flex direction="column">
            <Flex align="center" justify="space-between" mb={2}>
              <Text fz="0.75rem" color="dark.4" fw={500}>
                Filter by condition
              </Text>
            </Flex>

            <Popover withinPortal shadow="sm" opened={conditionDropdownOpened} onClose={closeConditionDropdown}>
              <Popover.Target>
                <TextInput
                  placeholder="0 condition selected"
                  size="xs"
                  onClick={toggleConditionDropdown}
                  icon={<IconActivityHeartbeat size={18} />}
                  value={conditionFilter ? conditionFilter.displayConditionName : ''}
                  sx={{
                    '*': {
                      cursor: 'pointer',
                    },
                  }}
                  rightSection={
                    <Flex
                      align="center"
                      justify="center"
                      sx={(theme) => ({
                        color: theme.colors.gray[6],
                      })}
                    >
                      {conditionFilter ? (
                        <ActionIcon size="xs" onClick={() => setConditionFilter(null)}>
                          <IconX size={12} />
                        </ActionIcon>
                      ) : (
                        <IconSelector size={12} onClick={toggleConditionDropdown} />
                      )}
                    </Flex>
                  }
                />
              </Popover.Target>

              <Popover.Dropdown p={0}>
                <ConditionDropdown
                  onConfirm={(conditions) => setConditionFilter(conditions[0])}
                  onClose={closeConditionDropdown}
                  multiple={false}
                  noConfirm
                  maxWidth={420}
                />
              </Popover.Dropdown>
            </Popover>
          </Flex>

          <Flex direction="column">
            <Flex align="center" justify="space-between" mb={2}>
              <Text fz="0.75rem" color="dark.4" fw={500}>
                Filter by author
              </Text>
            </Flex>

            <Select
              placeholder="0 person selected"
              data={authorOptions}
              onChange={handleChangeAuthor}
              value={authorFilter?.id || null}
              size="xs"
              icon={<IconUser size={16} />}
              rightSection={
                <Flex
                  align="center"
                  justify="center"
                  sx={(theme) => ({
                    color: theme.colors.gray[6],
                  })}
                >
                  {authorFilter ? (
                    <ActionIcon size="xs" onClick={() => setAuthorFilter(null)}>
                      <IconX size={12} />
                    </ActionIcon>
                  ) : (
                    <IconSelector size={12} />
                  )}
                </Flex>
              }
            />
          </Flex>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export const NoteFilters = memo(NoteFiltersBase);
