import React, { memo, useCallback, useRef } from 'react';
import { Box } from '@mantine/core';
import 'pdfjs-dist';

import { setNoteFileSnippet, setNoteImageUrl } from '@/pageAI/states/notes';
import { useClientStore } from '@/pageAI/states/client';
import SelectionBox from '../../common/SelectionBox/SelectionBox';
import { useSelectionBox } from '../../common/SelectionBox';
import { getImageURLFromCanvas } from '@/shared/lib/dom';
import { mapViewerCoordinatesToCanvas, RectBox } from '@/shared/lib/pdf/coordinates';

interface PDFViewerProps {
  scale: number;
  pageNumber: string;
  draggingEnabled?: boolean;
  onDragEnd?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const PDFViewerBase = ({ scale, pageNumber, onDragEnd, draggingEnabled = true }: PDFViewerProps) => {
  const viewerRef = useRef<HTMLDivElement>(null);
  // const imagePosition = useNotesStore((state) => state.imagePosition);
  // const initialScale = useRef(scale);
  // const previousScale = usePrevious(scale);

  const { isDragging, box, onMouseDown, onMouseMove, onMouseUp } = useSelectionBox();

  const handleViewerMouseDown = (e: React.MouseEvent) => {
    if (!viewerRef.current) return;

    onMouseDown(e, viewerRef.current);
  };

  const handleViewerMouseMove = (e: React.MouseEvent) => {
    if (!viewerRef.current) return;

    onMouseMove(e, viewerRef.current);
  };

  const calculateImagePosition = useCallback(
    (box: RectBox) => {
      if (!viewerRef.current) return;

      const currentPageCanvas = document.querySelector(
        `#mainContainer [data-page-number="${pageNumber}"] canvas`,
      ) as HTMLCanvasElement | null;

      if (!currentPageCanvas) return;

      const imagePosition = mapViewerCoordinatesToCanvas(box, viewerRef.current, currentPageCanvas);

      return { canvas: currentPageCanvas, position: imagePosition };
    },
    [pageNumber],
  );

  const handleViewerMouseUp = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      // When dragging completes
      if (isDragging && box && viewerRef.current) {
        const result = calculateImagePosition(box);

        if (!result) return;

        const { canvas, position } = result;

        const imageURL = getImageURLFromCanvas(canvas, position);

        if (!imageURL) return;

        const client = useClientStore.getState().selectedClient;

        if (!client) return;

        setNoteFileSnippet('', false);
        setNoteImageUrl(imageURL, box);

        onDragEnd?.(event);
      }

      console.log('handleViewerMouseUp', isDragging, box);

      onMouseUp();
    },
    [isDragging, box, onDragEnd, calculateImagePosition, onMouseUp],
  );

  // Detect when the canvas changes using mutation observer and update the image position
  // useEffect(() => {
  //   if (!imagePosition || !previousScale || previousScale === scale) return;

  //   const scaleFactor = initialScale.current / scale;

  //   console.log('scale', scale, 'previousScale', previousScale, 'scaleFactor', scaleFactor);

  //   setNoteImagePosition({
  //     x: imagePosition.x * scaleFactor,
  //     y: imagePosition.y * scaleFactor,
  //     width: imagePosition.width * scaleFactor,
  //     height: imagePosition.height * scaleFactor,
  //   });
  // }, [imagePosition, scale, previousScale]);

  return (
    <>
      <Box
        id="viewer"
        className="pdfViewer"
        ref={viewerRef}
        {...(draggingEnabled
          ? {
              onMouseMove: handleViewerMouseMove,
              onMouseDown: handleViewerMouseDown,
              onMouseUp: handleViewerMouseUp,
            }
          : {})}
        sx={{
          textLayer: {
            cursor: 'text',
          },
          ...(isDragging
            ? {
                userSelect: 'none',
                '*': {
                  userSelect: 'none',
                },
              }
            : {}),
        }}
      />

      {isDragging && <SelectionBox box={box} />}

      {/* imagePosition && <SelectionBox box={imagePosition} lighter /> */}
    </>
  );
};

export const PDFViewer = memo(PDFViewerBase);
