import { memo, useCallback, useEffect, useMemo } from 'react';
import { Flex, ScrollArea, Text } from '@mantine/core';

import {
  isReviewingIncrementalChangesSelector,
  useUnifiedStore,
  updatedConditionIdToReviewSelector,
  onlyReviewNewIncrementalChangesSelector,
  conditionListTabSelector,
  setConditionListTab,
} from '@/pageAI/states/unified';
import { conditionsSelector, selectedSimpleConditionSelector } from '@/pageAI/states/unified';
import { useConditionReview } from '@/pageAI/hooks/conditions/reviewVersion/useConditionReview';
import { SimpleCondition } from '@/pageAI/@types/summaries';
import { MedicalConditionMenu } from '../../MedicalConditionMenu';
import { OnlyReviewNewIncrementalChangesToggle } from '../OnlyReviewNewIncrementalChangesToggle';
import { isArchivedCondition } from '@/pageAI/services/medicalConditions';

interface CurrentConditionMenuProps {
  onSelect?: (condition: SimpleCondition) => void;
  selected?: string[];
  checkboxMode?: boolean;
}

const CurrentConditionMenuBase = ({ onSelect, selected, checkboxMode }: CurrentConditionMenuProps) => {
  const conditions = useUnifiedStore(conditionsSelector);
  const selectedCondition = useUnifiedStore(selectedSimpleConditionSelector);
  const isReviewing = useUnifiedStore(isReviewingIncrementalChangesSelector);
  const updatedConditionIdToReview = useUnifiedStore(updatedConditionIdToReviewSelector);
  const onlyReviewNewChanges = useUnifiedStore(onlyReviewNewIncrementalChangesSelector);
  const conditionListTab = useUnifiedStore(conditionListTabSelector);

  const filteredConditions = useMemo(() => {
    if (conditionListTab === 'Archived') return conditions.filter(isArchivedCondition);

    return conditions.filter((condition) => !isArchivedCondition(condition));
  }, [conditions, conditionListTab]);

  useEffect(() => {
    if (filteredConditions.length === 0 && conditionListTab === 'Archived') {
      setConditionListTab('All');
    }
  }, [filteredConditions, conditionListTab]);

  const { conditionsNeedingReview, conditionIdsNeedingReview, newConditionIds } = useConditionReview();

  const selectedMedicalConditionId = selectedCondition?.id || conditions[0]?.id;

  const handleSelect = useCallback(
    (condition: SimpleCondition) => {
      onSelect?.(condition);
    },
    [onSelect],
  );

  return (
    <Flex
      direction="column"
      sx={(theme) => ({
        width: '100%',
        height: '100%',
      })}
    >
      <ScrollArea.Autosize
        mah={checkboxMode ? 'calc(100vh - 156px)' : 'calc(100vh - 116px)'}
        sx={{
          '> div': {
            width: '100%',
          },
        }}
      >
        {isReviewing && (
          <Flex
            align="center"
            justify="space-between"
            gap={4}
            sx={(theme) => ({
              position: 'sticky',
              top: 0,
              zIndex: 1,
              background: `1px solid ${theme.colors.red[2]}`,
            })}
            mt={12}
            mb={4}
            px={16}
          >
            <Text fw={600} color="orange.6" fz="0.875rem" sx={{ whiteSpace: 'nowrap' }}>
              Review conditions
            </Text>

            <OnlyReviewNewIncrementalChangesToggle />
          </Flex>
        )}

        <MedicalConditionMenu
          loading={conditions.length === 0}
          selected={!selected ? [selectedMedicalConditionId] : selected}
          onSelect={handleSelect}
          maxHeight={null}
          checkboxMode={checkboxMode}
          showConditionActionMenu
          {...(isReviewing
            ? {
                medicalConditions: onlyReviewNewChanges ? conditionsNeedingReview : conditions,
                searchable: false,
                newlyAdded: newConditionIds,
                bolded: conditionIdsNeedingReview,
                highlighted: updatedConditionIdToReview ? [updatedConditionIdToReview] : [],
              }
            : {
                medicalConditions: filteredConditions,
              })}
        />
      </ScrollArea.Autosize>
    </Flex>
  );
};

export const CurrentConditionMenu = memo(CurrentConditionMenuBase);
