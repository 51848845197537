import { memo, useRef, useState } from 'react';
import { Button, Container, Flex, LoadingOverlay, ScrollArea } from '@mantine/core';
import { Link } from 'react-router-dom';

import { ConversationHeader } from '../ConversationHeader';
import { useEnsureSendMessage } from './ConversationLayout.utils';
import { ConversationMessages } from '../ConversationMessages';
import { ConversationLayoutProps } from './ConversationLayout.types';
import { NotFoundPlaceholder } from '@/shared/components/common/placeholders/NotFoundPlaceholder';
import { useResizeObserver } from '@/shared/hooks/dom/useResizeObserver';
import { ConversationMessage } from '@/shared/@types';
import { ChatBox } from '@/shared/components/oldChat/ChatBox';
import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';

const ConversationLayoutBase = ({
  conversation,
  upsertConversationMessage,
  isLoading,
  isError,
  sx,
  minimalChatBox = false,
}: ConversationLayoutProps) => {
  const scrollAreaRef = useRef<HTMLDivElement | null>(null);
  const [chatBoxWidth, setChatBoxWidth] = useState(928);
  useEnsureSendMessage(conversation, upsertConversationMessage);

  const handleResize = (contentRect: DOMRectReadOnly) => {
    setChatBoxWidth(contentRect.width);
  };

  const [ref] = useResizeObserver(handleResize);

  const handleMessageSent = (message: ConversationMessage) => {
    upsertConversationMessage?.(message);

    if (!scrollAreaRef.current) return;

    const innerScrollAreaElement = scrollAreaRef.current.querySelector('.ghost-ScrollArea-viewport');

    if (!innerScrollAreaElement) return;

    setTimeout(
      () => innerScrollAreaElement.scrollTo({ left: 0, top: innerScrollAreaElement.scrollHeight, behavior: 'smooth' }),
      200,
    );
  };

  if (isLoading) return <LoadingOverlay visible />;

  if (isError)
    return (
      <Flex align="center" justify="center" sx={{ height: '100%' }}>
        <ErrorPlaceholder description="We could not load the conversation. Please try again later." />
      </Flex>
    );

  if (!conversation)
    return (
      <NotFoundPlaceholder
        title="Not Found"
        description="The conversation you are trying to open does not exist. If you think this is an error, please contact us."
        action={
          <Link to="/chat">
            <Button size="md">Create a new conversation</Button>
          </Link>
        }
      />
    );

  return (
    <ScrollArea ref={scrollAreaRef} sx={{ width: '100%', height: '100vh' }}>
      <Container>
        <Flex ref={ref} direction="column" sx={{ padding: '40px 0 120px 0', ...sx }}>
          <ConversationHeader conversationName={conversation.name} />

          <ConversationMessages
            conversationId={conversation.id}
            messages={[...conversation.messages.nodes].reverse()}
          />

          <ChatBox
            sx={{ position: 'fixed', bottom: 24, width: chatBoxWidth, minWidth: 0 }}
            onMessageSent={handleMessageSent}
            hideRetrieverTypeSwitch={minimalChatBox}
            compact
          />
        </Flex>
      </Container>
    </ScrollArea>
  );
};

export const ConversationLayout = memo(ConversationLayoutBase);
