import React, { memo } from 'react';
import { ActionIcon, Flex, Sx, Text, Tooltip } from '@mantine/core';
import { IconArrowNarrowUp, IconKeyboard } from '@tabler/icons-react';

import { isMacOS } from '@/shared/lib/browser';
import { useNotesStore } from '@/pageAI/states/notes';
import { FetchStatus } from '@/shared/@types';

interface ConditionSearchTermHintProps {
  onClick?: (event: React.MouseEvent) => void;
  loading?: boolean;
  sx?: Sx;
  collapsed?: boolean;
}

const AddNoteButtonBase = ({ onClick, loading = false, sx, collapsed }: ConditionSearchTermHintProps) => {
  const fetchSnippetStatus = useNotesStore((state) => state.fetchSnippetStatus);
  const isFetchingSnippet = fetchSnippetStatus === FetchStatus.LOADING;

  const renderKeyboardText = (text: React.ReactNode) => {
    return (
      <Text
        component="strong"
        fz="0.75rem"
        sx={(theme) => ({
          padding: '2px 6px',
          background: theme.fn.lighten(theme.colors.dark[5], 0.11),
          borderRadius: 4,
        })}
      >
        {text}
      </Text>
    );
  };

  return (
    <Tooltip
      key={String([collapsed, loading].join(''))}
      withArrow
      withinPortal
      openDelay={1000}
      label={
        <Flex align="center" gap={6}>
          <IconKeyboard size={14} /> You can press
          {renderKeyboardText(isMacOS() ? 'Cmd' : 'Ctrl')} + {renderKeyboardText('Enter')} to submit the note.
        </Flex>
      }
    >
      <ActionIcon
        size="xs"
        color="dark.4"
        variant="filled"
        radius="50%"
        sx={sx}
        onClick={onClick}
        loading={loading}
        disabled={isFetchingSnippet}
      >
        <IconArrowNarrowUp size={14} />
      </ActionIcon>
    </Tooltip>
  );
};

export const AddNoteButton = memo(AddNoteButtonBase);
