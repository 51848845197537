import { memo } from 'react';
import { Flex, Skeleton } from '@mantine/core';

import { MessagePlaceholderProps } from './MessagePlaceholder.types';
import { MessageRole } from '@/pageAI/gql/graphql';
import { UserAvatar } from '@/shared/components/users/UserAvatar';
import { BotMessageAvatar } from '@/shared/components/oldChat/BotMessageAvatar/BotMessageAvatar';

const MessagePlaceholderBase: React.FC<MessagePlaceholderProps> = ({ role = MessageRole.Ai }) => {
  return (
    <>
      <Flex align="center" gap="xs">
        {role === MessageRole.Human ? <UserAvatar /> : <BotMessageAvatar />}

        <Flex direction="column" gap={6} mt={4} sx={{ width: '100%' }}>
          <Skeleton height={20} />

          {role === MessageRole.Ai && (
            <>
              <Skeleton height={20} />

              <Skeleton height={20} />
            </>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export const MessagePlaceholder = memo(MessagePlaceholderBase);
