import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ActionIcon, Box, Flex, Select, Tooltip } from '@mantine/core';
import { useContextMenu } from 'mantine-contextmenu';
import 'pdfjs-dist';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { webViewerLoad } from './viewer.mjs';

import { PDFHighlightTextsFunctionOptions, PDFPreviewerProps } from './PDFPreviewer.types';
import { UnusedSidebar } from './UnusedSidebar';
import { usePersistentPDFScale } from '@/shared/hooks/files/usePersistentPDFScale';
import { truthy } from '@/shared/utils/boolean';
import { downloadPdfEventBus } from '../../files/AssetSidebarActionMenu';
import { IconMinus, IconPlus, IconRotateClockwise2, IconSearch } from '@tabler/icons-react';
import { setNoteFileSnippet, setNoteImageUrl } from '@/pageAI/states/notes';
import { FileNotes } from '@/pageAI/components/notes/FileNotes';
import { takeNoteFromFile } from '@/pageAI/services/notes';

import IconNotePlus from '@/shared/icons/note-plus.svg?react';
import { useClientStore } from '@/pageAI/states/client';
import { removeLineBreaks } from '@/shared/utils/string';
import { PDFViewerContainer } from '../PDFViewerContainer';

const toggleFind = () => {
  const findBar = document.querySelector('#findbar') as HTMLDivElement | undefined;

  if (!findBar) return;

  const isHidden = findBar.className.includes('hidden');

  if (isHidden) return;

  const searchButton = document.querySelector('#viewFind') as HTMLButtonElement | undefined;

  searchButton?.click();
};

const PDFPreviewerBase = ({
  fileAsset,
  fileUrl,
  initialPageIndex,
  highlightedTexts,
  highlightWithinPages,
  onPageChange,
  draggingEnabled = true,
}: PDFPreviewerProps) => {
  const { showContextMenu, isContextMenuVisible } = useContextMenu();
  const isContextMenuVisibleRef = useRef(isContextMenuVisible);

  const initialPage = (initialPageIndex || 0) + 1;
  const [scale, setScale] = usePersistentPDFScale();
  const initialScale = scale;

  const lastSavedValidPageNumber = useRef<number>(initialPage);
  const [pageNumber, setPageNumber] = useState(String(initialPage));
  const [status, setStatus] = useState<'loading' | 'loaded' | 'error'>('loading');
  const shouldHighlight = useMemo(() => highlightedTexts && highlightedTexts.length > 0, [highlightedTexts]);
  const highlightTextsRef = useRef<(options: PDFHighlightTextsFunctionOptions) => void>();
  const goToPageRef = useRef<(pageNumber: number) => void>();
  const changeScaleRef = useRef<(scale: number) => void>();
  const zoomInRef = useRef<() => void>();
  const zoomOutRef = useRef<() => void>();
  const rotateCwRef = useRef<() => void>();
  const downloadRef = useRef<({ filename }: { filename: string }) => void>();

  const handlePageChange = useCallback(
    ({ pageNumber: newPageNumber }: { pageNumber: number }) => {
      setPageNumber(String(newPageNumber));

      onPageChange?.(newPageNumber - 1);
    },
    [onPageChange],
  );

  const handlePageNumberInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newPageNumber = Number(event.target.value ?? '');

      if (Number.isNaN(newPageNumber)) return;

      if (newPageNumber === 0) {
        lastSavedValidPageNumber.current = Number(pageNumber);

        return setPageNumber('');
      }

      goToPageRef.current?.(newPageNumber);
    },
    [pageNumber, goToPageRef],
  );

  const handlePageNumberInputBlur = useCallback(() => {
    if (!pageNumber || Number.isNaN(Number(pageNumber))) {
      setPageNumber(String(lastSavedValidPageNumber.current));
    }
  }, [pageNumber]);

  const handleChangeScale = useCallback(
    (value: string) => {
      const newScale = Number(value);

      if (Number.isNaN(newScale) || newScale <= 0) return;

      changeScaleRef.current?.(newScale);
    },
    [changeScaleRef],
  );

  const zoomIn = useCallback(() => {
    zoomInRef.current?.();
  }, []);

  const zoomOut = useCallback(() => {
    zoomOutRef.current?.();
  }, []);

  useEffect(() => {
    const unsubscribe = downloadPdfEventBus.subscribe(({ filename }) => {
      downloadRef.current?.({ filename });
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    (async () => {
      setStatus('loading');

      try {
        const { highlightTexts, goToPage, changeScale, zoomIn, zoomOut, rotateCw, download } = await webViewerLoad(
          fileUrl,
          {
            onPageChange: handlePageChange,
            onScaleChange: ({ scale }: { scale: number }) => {
              if (Number.isNaN(scale) || scale <= 0) return;

              setScale(scale);
            },
            initialScale,
          },
        );

        highlightTextsRef.current = highlightTexts;
        goToPageRef.current = goToPage;
        changeScaleRef.current = changeScale;
        zoomInRef.current = zoomIn;
        zoomOutRef.current = zoomOut;
        rotateCwRef.current = rotateCw;
        downloadRef.current = download;

        setStatus('loaded');
      } catch (error) {
        console.error(error);

        setStatus('error');
      }
    })();
  }, [fileUrl, handlePageChange]);

  useLayoutEffect(() => {
    toggleFind();
  }, [fileUrl]);

  useEffect(() => {
    if (status !== 'loaded') return;

    goToPageRef.current?.(initialPage);
    setPageNumber(String(initialPage));
  }, [status, initialPage]);

  useEffect(() => {
    if (status !== 'loaded') return;

    highlightTextsRef.current?.({ texts: highlightedTexts || [], pageIndices: highlightWithinPages || [] });
  }, [status, highlightedTexts, highlightWithinPages]);

  const handleContextMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const client = useClientStore.getState().selectedClient;

      return showContextMenu(
        [
          {
            key: 'share',
            icon: <IconNotePlus width={16} height={16} />,
            title: 'Take note',
            onClick: () => {
              if (!client || !fileAsset) return;

              takeNoteFromFile({ client, fileAsset });
            },
          },
        ],
        { sx: { minWidth: 164 } },
      )(event);
    },
    [fileAsset, showContextMenu],
  );

  const handleViewerDragEnd = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      handleContextMenu(event);
    },
    [handleContextMenu],
  );

  const handleMouseDown = () => {
    isContextMenuVisibleRef.current = isContextMenuVisible;
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    const selection = window.getSelection();
    const selectedText = selection?.toString() || '';

    if (selectedText.length > 0) {
      setNoteFileSnippet(removeLineBreaks(selectedText, ' '));
      setNoteImageUrl(null);

      if (!isContextMenuVisibleRef.current) handleContextMenu(event);
    }
  };

  return (
    <Box
      sx={{ width: '100%', height: '100%', borderTop: '1px solid #dee2e6' }}
      {...(draggingEnabled
        ? { onMouseDown: handleMouseDown, onMouseUp: handleMouseUp, onContextMenu: handleContextMenu }
        : {})}
    >
      {fileAsset && <FileNotes fileAsset={fileAsset} sx={{ zIndex: 101 }} />}

      <div id="outerContainer">
        <UnusedSidebar />

        <div id="mainContainer" className={!shouldHighlight ? 'no-highlight' : ''}>
          <div className="findbar hidden doorHanger" id="findbar">
            <div id="findbarInputContainer">
              <span className="loadingInput end">
                <input
                  id="findInput"
                  className="toolbarField"
                  title="Find"
                  placeholder="Find in document…"
                  tabIndex={91}
                  data-l10n-id="pdfjs-find-input"
                  aria-invalid="false"
                  style={{ fontSize: '0.875rem' }}
                />
              </span>
              <div className="splitToolbarButton">
                <button
                  id="findPrevious"
                  className="toolbarButton"
                  title="Find the previous occurrence of the phrase"
                  tabIndex={92}
                  data-l10n-id="pdfjs-find-previous-button"
                >
                  <span data-l10n-id="pdfjs-find-previous-button-label">Previous</span>
                </button>
                <div className="splitToolbarButtonSeparator"></div>
                <button
                  id="findNext"
                  className="toolbarButton"
                  title="Find the next occurrence of the phrase"
                  tabIndex={93}
                  data-l10n-id="pdfjs-find-next-button"
                >
                  <span data-l10n-id="pdfjs-find-next-button-label">Next</span>
                </button>
              </div>
            </div>

            <div id="findbarOptionsOneContainer">
              <input type="checkbox" id="findHighlightAll" className="toolbarField" tabIndex={94} />
              <label
                htmlFor="findHighlightAll"
                className="toolbarLabel"
                data-l10n-id="pdfjs-find-highlight-checkbox"
                style={{ display: 'none' }}
              >
                Highlight All
              </label>
              <input type="checkbox" id="findMatchCase" className="toolbarField" tabIndex={95} />
              <label
                htmlFor="findMatchCase"
                className="toolbarLabel"
                data-l10n-id="pdfjs-find-match-case-checkbox-label"
              >
                Match Case
              </label>
            </div>
            <div id="findbarOptionsTwoContainer">
              <input type="checkbox" id="findMatchDiacritics" className="toolbarField" tabIndex={96} />
              <label
                htmlFor="findMatchDiacritics"
                className="toolbarLabel hidden"
                data-l10n-id="pdfjs-find-match-diacritics-checkbox-label"
              >
                Match Diacritics
              </label>
              <input type="checkbox" id="findEntireWord" className="toolbarField" tabIndex={97} />
              <label
                htmlFor="findEntireWord"
                className="toolbarLabel"
                data-l10n-id="pdfjs-find-entire-word-checkbox-label"
              >
                Whole Words
              </label>
            </div>

            <div id="findbarMessageContainer" aria-live="polite">
              <span id="findResultsCount" className="toolbarLabel"></span>
              <span id="findMsg" className="toolbarLabel"></span>
            </div>
          </div>

          <div className="editorParamsToolbar hidden doorHangerRight" id="editorHighlightParamsToolbar">
            <div id="highlightParamsToolbarContainer" className="editorParamsToolbarContainer">
              <div id="editorHighlightColorPicker" className="colorPicker">
                <span
                  id="highlightColorPickerLabel"
                  className="editorParamsLabel"
                  data-l10n-id="pdfjs-editor-highlight-colorpicker-label"
                >
                  Highlight color
                </span>
              </div>
              <div id="editorHighlightThickness">
                <label
                  htmlFor="editorFreeHighlightThickness"
                  className="editorParamsLabel"
                  data-l10n-id="pdfjs-editor-free-highlight-thickness-input"
                >
                  Thickness
                </label>
                <div className="thicknessPicker">
                  <input
                    type="range"
                    id="editorFreeHighlightThickness"
                    className="editorParamsSlider"
                    data-l10n-id="pdfjs-editor-free-highlight-thickness-title"
                    min="8"
                    max="24"
                    step="1"
                    tabIndex={10}
                  />
                </div>
              </div>
              <div id="editorHighlightVisibility">
                <div className="divider"></div>
                <div className="toggler">
                  <label
                    htmlFor="editorHighlightShowAll"
                    className="editorParamsLabel"
                    data-l10n-id="pdfjs-editor-highlight-show-all-button-label"
                  >
                    Show all
                  </label>
                  <button
                    id="editorHighlightShowAll"
                    className="toggle-button"
                    data-l10n-id="pdfjs-editor-highlight-show-all-button"
                    aria-pressed="true"
                    tabIndex={10}
                  ></button>
                </div>
              </div>
            </div>
          </div>

          <div className="editorParamsToolbar hidden doorHangerRight" id="editorFreeTextParamsToolbar">
            <div className="editorParamsToolbarContainer">
              <div className="editorParamsSetter">
                <label
                  htmlFor="editorFreeTextColor"
                  className="editorParamsLabel"
                  data-l10n-id="pdfjs-editor-free-text-color-input"
                >
                  Color
                </label>
                <input type="color" id="editorFreeTextColor" className="editorParamsColor" tabIndex={10} />
              </div>
              <div className="editorParamsSetter">
                <label
                  htmlFor="editorFreeTextFontSize"
                  className="editorParamsLabel"
                  data-l10n-id="pdfjs-editor-free-text-size-input"
                >
                  Size
                </label>
                <input
                  type="range"
                  id="editorFreeTextFontSize"
                  className="editorParamsSlider"
                  min="5"
                  max="100"
                  step="1"
                  tabIndex={10}
                />
              </div>
            </div>
          </div>

          <div className="editorParamsToolbar hidden doorHangerRight" id="editorInkParamsToolbar">
            <div className="editorParamsToolbarContainer">
              <div className="editorParamsSetter">
                <label
                  htmlFor="editorInkColor"
                  className="editorParamsLabel"
                  data-l10n-id="pdfjs-editor-ink-color-input"
                >
                  Color
                </label>
                <input type="color" id="editorInkColor" className="editorParamsColor" tabIndex={10} />
              </div>
              <div className="editorParamsSetter">
                <label
                  htmlFor="editorInkThickness"
                  className="editorParamsLabel"
                  data-l10n-id="pdfjs-editor-ink-thickness-input"
                >
                  Thickness
                </label>
                <input
                  type="range"
                  id="editorInkThickness"
                  className="editorParamsSlider"
                  min="1"
                  max="20"
                  step="1"
                  tabIndex={10}
                />
              </div>
              <div className="editorParamsSetter">
                <label
                  htmlFor="editorInkOpacity"
                  className="editorParamsLabel"
                  data-l10n-id="pdfjs-editor-ink-opacity-input"
                >
                  Opacity
                </label>
                <input
                  type="range"
                  id="editorInkOpacity"
                  className="editorParamsSlider"
                  min="1"
                  max="100"
                  step="1"
                  tabIndex={10}
                />
              </div>
            </div>
          </div>

          <div className="editorParamsToolbar hidden doorHangerRight" id="editorStampParamsToolbar">
            <div className="editorParamsToolbarContainer">
              <button
                id="editorStampAddImage"
                className="secondaryToolbarButton"
                title="Add image"
                tabIndex={10}
                data-l10n-id="pdfjs-editor-stamp-add-image-button"
              >
                <span className="editorParamsLabel" data-l10n-id="pdfjs-editor-stamp-add-image-button-label">
                  Add image
                </span>
              </button>
            </div>
          </div>

          <div id="secondaryToolbar" className="secondaryToolbar hidden doorHangerRight">
            <div id="secondaryToolbarButtonContainer">
              <button
                id="secondaryOpenFile"
                className="secondaryToolbarButton"
                title="Open File"
                tabIndex={51}
                data-l10n-id="pdfjs-open-file-button"
              >
                <span data-l10n-id="pdfjs-open-file-button-label">Open</span>
              </button>

              <button
                id="secondaryPrint"
                className="secondaryToolbarButton visibleMediumView"
                title="Print"
                tabIndex={52}
                data-l10n-id="pdfjs-print-button"
              >
                <span data-l10n-id="pdfjs-print-button-label">Print</span>
              </button>

              <button
                id="secondaryDownload"
                className="secondaryToolbarButton visibleMediumView"
                title="Save"
                tabIndex={53}
                data-l10n-id="pdfjs-save-button"
              >
                <span data-l10n-id="pdfjs-save-button-label">Save</span>
              </button>

              <div className="horizontalToolbarSeparator"></div>

              <button
                id="presentationMode"
                className="secondaryToolbarButton"
                title="Switch to Presentation Mode"
                tabIndex={54}
                data-l10n-id="pdfjs-presentation-mode-button"
              >
                <span data-l10n-id="pdfjs-presentation-mode-button-label">Presentation Mode</span>
              </button>

              {/* eslint-disable-next-line */}
              <a
                href="#"
                id="viewBookmark"
                className="secondaryToolbarButton"
                title="Current Page (View URL from Current Page)"
                tabIndex={55}
                data-l10n-id="pdfjs-bookmark-button"
              >
                <span data-l10n-id="pdfjs-bookmark-button-label">Current Page</span>
              </a>

              <div id="viewBookmarkSeparator" className="horizontalToolbarSeparator"></div>

              <button
                id="firstPage"
                className="secondaryToolbarButton"
                title="Go to First Page"
                tabIndex={56}
                data-l10n-id="pdfjs-first-page-button"
              >
                <span data-l10n-id="pdfjs-first-page-button-label">Go to First Page</span>
              </button>
              <button
                id="lastPage"
                className="secondaryToolbarButton"
                title="Go to Last Page"
                tabIndex={57}
                data-l10n-id="pdfjs-last-page-button"
              >
                <span data-l10n-id="pdfjs-last-page-button-label">Go to Last Page</span>
              </button>

              <div className="horizontalToolbarSeparator"></div>

              <button
                id="pageRotateCw"
                className="secondaryToolbarButton"
                title="Rotate Clockwise"
                tabIndex={58}
                data-l10n-id="pdfjs-page-rotate-cw-button"
              >
                <span data-l10n-id="pdfjs-page-rotate-cw-button-label">Rotate Clockwise</span>
              </button>
              <button
                id="pageRotateCcw"
                className="secondaryToolbarButton"
                title="Rotate Counterclockwise"
                tabIndex={59}
                data-l10n-id="pdfjs-page-rotate-ccw-button"
              >
                <span data-l10n-id="pdfjs-page-rotate-ccw-button-label">Rotate Counterclockwise</span>
              </button>

              <div className="horizontalToolbarSeparator"></div>

              <div id="cursorToolButtons" role="radiogroup">
                <button
                  id="cursorSelectTool"
                  className="secondaryToolbarButton toggled"
                  title="Enable Text Selection Tool"
                  tabIndex={60}
                  data-l10n-id="pdfjs-cursor-text-select-tool-button"
                  role="radio"
                  aria-checked="true"
                >
                  <span data-l10n-id="pdfjs-cursor-text-select-tool-button-label">Text Selection Tool</span>
                </button>
                <button
                  id="cursorHandTool"
                  className="secondaryToolbarButton"
                  title="Enable Hand Tool"
                  tabIndex={61}
                  data-l10n-id="pdfjs-cursor-hand-tool-button"
                  role="radio"
                  aria-checked="false"
                >
                  <span data-l10n-id="pdfjs-cursor-hand-tool-button-label">Hand Tool</span>
                </button>
              </div>

              <div className="horizontalToolbarSeparator"></div>

              <div id="scrollModeButtons" role="radiogroup">
                <button
                  id="scrollPage"
                  className="secondaryToolbarButton"
                  title="Use Page Scrolling"
                  tabIndex={62}
                  data-l10n-id="pdfjs-scroll-page-button"
                  role="radio"
                  aria-checked="false"
                >
                  <span data-l10n-id="pdfjs-scroll-page-button-label">Page Scrolling</span>
                </button>
                <button
                  id="scrollVertical"
                  className="secondaryToolbarButton toggled"
                  title="Use Vertical Scrolling"
                  tabIndex={63}
                  data-l10n-id="pdfjs-scroll-vertical-button"
                  role="radio"
                  aria-checked="true"
                >
                  <span data-l10n-id="pdfjs-scroll-vertical-button-label">Vertical Scrolling</span>
                </button>
                <button
                  id="scrollHorizontal"
                  className="secondaryToolbarButton"
                  title="Use Horizontal Scrolling"
                  tabIndex={64}
                  data-l10n-id="pdfjs-scroll-horizontal-button"
                  role="radio"
                  aria-checked="false"
                >
                  <span data-l10n-id="pdfjs-scroll-horizontal-button-label">Horizontal Scrolling</span>
                </button>
                <button
                  id="scrollWrapped"
                  className="secondaryToolbarButton"
                  title="Use Wrapped Scrolling"
                  tabIndex={65}
                  data-l10n-id="pdfjs-scroll-wrapped-button"
                  role="radio"
                  aria-checked="false"
                >
                  <span data-l10n-id="pdfjs-scroll-wrapped-button-label">Wrapped Scrolling</span>
                </button>
              </div>

              <div className="horizontalToolbarSeparator"></div>

              <div id="spreadModeButtons" role="radiogroup">
                <button
                  id="spreadNone"
                  className="secondaryToolbarButton toggled"
                  title="Do not join page spreads"
                  tabIndex={66}
                  data-l10n-id="pdfjs-spread-none-button"
                  role="radio"
                  aria-checked="true"
                >
                  <span data-l10n-id="pdfjs-spread-none-button-label">No Spreads</span>
                </button>
                <button
                  id="spreadOdd"
                  className="secondaryToolbarButton"
                  title="Join page spreads starting with odd-numbered pages"
                  tabIndex={67}
                  data-l10n-id="pdfjs-spread-odd-button"
                  role="radio"
                  aria-checked="false"
                >
                  <span data-l10n-id="pdfjs-spread-odd-button-label">Odd Spreads</span>
                </button>
                <button
                  id="spreadEven"
                  className="secondaryToolbarButton"
                  title="Join page spreads starting with even-numbered pages"
                  tabIndex={68}
                  data-l10n-id="pdfjs-spread-even-button"
                  role="radio"
                  aria-checked="false"
                >
                  <span data-l10n-id="pdfjs-spread-even-button-label">Even Spreads</span>
                </button>
              </div>

              <div className="horizontalToolbarSeparator"></div>

              <button
                id="documentProperties"
                className="secondaryToolbarButton"
                title="Document Properties…"
                tabIndex={69}
                data-l10n-id="pdfjs-document-properties-button"
                aria-controls="documentPropertiesDialog"
              >
                <span data-l10n-id="pdfjs-document-properties-button-label">Document Properties…</span>
              </button>
            </div>
          </div>

          <div className="toolbar">
            <div id="toolbarContainer">
              <div id="toolbarViewer">
                <div id="toolbarViewerLeft">
                  <button
                    id="sidebarToggle"
                    className="toolbarButton"
                    title="Toggle Sidebar"
                    tabIndex={11}
                    data-l10n-id="pdfjs-toggle-sidebar-button"
                    aria-expanded="false"
                    aria-controls="sidebarContainer"
                  >
                    <span data-l10n-id="pdfjs-toggle-sidebar-button-label">Toggle Sidebar</span>
                  </button>
                  <div className="toolbarButtonSpacer"></div>
                  <ActionIcon
                    mt={1}
                    color="dark.3"
                    id="viewFind"
                    title="Find in Document"
                    data-l10n-id="pdfjs-findbar-button"
                    aria-expanded="false"
                    aria-controls="findbar"
                  >
                    <IconSearch size={16} />
                  </ActionIcon>
                  <div className="splitToolbarButton hiddenSmallView">
                    <button
                      className="toolbarButton"
                      title="Previous Page"
                      id="previous"
                      tabIndex={13}
                      data-l10n-id="pdfjs-previous-button"
                    >
                      <span data-l10n-id="pdfjs-previous-button-label">Previous</span>
                    </button>
                    <div className="splitToolbarButtonSeparator"></div>
                    <button
                      className="toolbarButton"
                      title="Next Page"
                      id="next"
                      tabIndex={14}
                      data-l10n-id="pdfjs-next-button"
                    >
                      <span data-l10n-id="pdfjs-next-button-label">Next</span>
                    </button>
                  </div>
                  <span className="loadingInput start">
                    <input
                      type="string"
                      id="pageNumber"
                      className="toolbarField"
                      title="Page"
                      value={pageNumber}
                      onChange={handlePageNumberInputChange}
                      onBlur={handlePageNumberInputBlur}
                      tabIndex={15}
                      data-l10n-id="pdfjs-page-input"
                      autoComplete="off"
                      style={{ minWidth: 48 }}
                    />
                  </span>
                  <span id="numPages" className="toolbarLabel"></span>
                </div>
                <div id="toolbarViewerRight">
                  <div id="editorModeButtons" className="splitToolbarButton toggled" role="radiogroup">
                    <button
                      id="editorHighlight"
                      className="toolbarButton"
                      hidden
                      disabled
                      title="Highlight"
                      role="radio"
                      aria-checked="false"
                      aria-controls="editorHighlightParamsToolbar"
                      tabIndex={31}
                      data-l10n-id="pdfjs-editor-highlight-button"
                    >
                      <span data-l10n-id="pdfjs-editor-highlight-button-label">Highlight</span>
                    </button>
                    <button
                      id="editorFreeText"
                      className="toolbarButton"
                      disabled
                      title="Text"
                      role="radio"
                      aria-checked="false"
                      aria-controls="editorFreeTextParamsToolbar"
                      tabIndex={32}
                      data-l10n-id="pdfjs-editor-free-text-button"
                    >
                      <span data-l10n-id="pdfjs-editor-free-text-button-label">Text</span>
                    </button>
                    <button
                      id="editorInk"
                      className="toolbarButton"
                      disabled
                      title="Draw"
                      role="radio"
                      aria-checked="false"
                      aria-controls="editorInkParamsToolbar"
                      tabIndex={33}
                      data-l10n-id="pdfjs-editor-ink-button"
                    >
                      <span data-l10n-id="pdfjs-editor-ink-button-label">Draw</span>
                    </button>
                    <button
                      id="editorStamp"
                      className="toolbarButton hidden"
                      disabled
                      title="Add or edit images"
                      role="radio"
                      aria-checked="false"
                      aria-controls="editorStampParamsToolbar"
                      tabIndex={34}
                      data-l10n-id="pdfjs-editor-stamp-button"
                    >
                      <span data-l10n-id="pdfjs-editor-stamp-button-label">Add or edit images</span>
                    </button>
                  </div>

                  <div id="editorModeSeparator" className="verticalToolbarSeparator"></div>

                  <button
                    id="print"
                    className="toolbarButton hiddenMediumView"
                    title="Print"
                    tabIndex={41}
                    data-l10n-id="pdfjs-print-button"
                  >
                    <span data-l10n-id="pdfjs-print-button-label">Print</span>
                  </button>

                  <button
                    id="download"
                    className="toolbarButton hidden"
                    title="Save"
                    tabIndex={42}
                    data-l10n-id="pdfjs-save-button"
                  >
                    <span data-l10n-id="pdfjs-save-button-label">Save</span>
                  </button>

                  <button
                    id="secondaryToolbarToggle"
                    className="toolbarButton"
                    title="Tools"
                    tabIndex={43}
                    data-l10n-id="pdfjs-tools-button"
                    aria-expanded="false"
                    aria-controls="secondaryToolbar"
                    style={{ display: 'none' }}
                  >
                    <span data-l10n-id="pdfjs-tools-button-label">Tools</span>
                  </button>
                </div>
                <div id="toolbarViewerMiddle">
                  <Flex align="center" mt={-1}>
                    <Tooltip label="Zoom out" withArrow>
                      <ActionIcon color="dark.3" onClick={zoomOut}>
                        <IconMinus size={18} />
                      </ActionIcon>
                    </Tooltip>

                    <Select
                      size="xs"
                      w={80}
                      withinPortal
                      mt={2}
                      mx={4}
                      sx={{ 'input.ghost-Select-input': { fontSize: '0.875rem' } }}
                      value={String(scale)}
                      onChange={handleChangeScale}
                      data={[
                        {
                          label: '50%',
                          value: '0.5',
                        },
                        {
                          label: '75%',
                          value: '0.75',
                        },
                        {
                          label: '100%',
                          value: '1',
                        },
                        {
                          label: '125%',
                          value: '1.25',
                        },
                        {
                          label: '150%',
                          value: '1.5',
                        },
                        {
                          label: '200%',
                          value: '2',
                        },
                        {
                          label: '300%',
                          value: '3',
                        },
                        {
                          label: '400%',
                          value: '4',
                        },
                        ![0.5, 0.75, 1, 1.25, 1.5, 2, 3, 4].includes(scale)
                          ? {
                              label: `${Math.round(scale * 100)}%`,
                              value: String(scale),
                            }
                          : null,
                      ].filter(truthy)}
                    />

                    <Tooltip label="Zoom in" withArrow>
                      <ActionIcon color="dark.3" onClick={zoomIn}>
                        <IconPlus size={18} />
                      </ActionIcon>
                    </Tooltip>

                    <Tooltip label="Rotate" withArrow>
                      <ActionIcon color="dark.3" onClick={() => rotateCwRef.current?.()}>
                        <IconRotateClockwise2 size={16} />
                      </ActionIcon>
                    </Tooltip>
                  </Flex>
                </div>
              </div>
              <div id="loadingBar" style={{ display: 'none' }}>
                <div className="progress">
                  <div className="glimmer"></div>
                </div>
              </div>
            </div>
          </div>

          <PDFViewerContainer
            scale={scale}
            status={status}
            pageNumber={pageNumber}
            onDragEnd={handleViewerDragEnd}
            draggingEnabled={draggingEnabled}
          />
        </div>

        <div id="dialogContainer">
          <dialog id="passwordDialog">
            <div className="row">
              <label htmlFor="password" id="passwordText" data-l10n-id="pdfjs-password-label">
                Enter the password to open this PDF file:
              </label>
            </div>
            <div className="row">
              <input type="password" id="password" className="toolbarField" />
            </div>
            <div className="buttonRow">
              <button id="passwordCancel" className="dialogButton">
                <span data-l10n-id="pdfjs-password-cancel-button">Cancel</span>
              </button>
              <button id="passwordSubmit" className="dialogButton">
                <span data-l10n-id="pdfjs-password-ok-button">OK</span>
              </button>
            </div>
          </dialog>
          <dialog id="documentPropertiesDialog">
            <div className="row">
              <span id="fileNameLabel" data-l10n-id="pdfjs-document-properties-file-name">
                File name:
              </span>
              <p id="fileNameField" aria-labelledby="fileNameLabel">
                -
              </p>
            </div>
            <div className="row">
              <span id="fileSizeLabel" data-l10n-id="pdfjs-document-properties-file-size">
                File size:
              </span>
              <p id="fileSizeField" aria-labelledby="fileSizeLabel">
                -
              </p>
            </div>
            <div className="separator"></div>
            <div className="row">
              <span id="titleLabel" data-l10n-id="pdfjs-document-properties-title">
                Title:
              </span>
              <p id="titleField" aria-labelledby="titleLabel">
                -
              </p>
            </div>
            <div className="row">
              <span id="authorLabel" data-l10n-id="pdfjs-document-properties-author">
                Author:
              </span>
              <p id="authorField" aria-labelledby="authorLabel">
                -
              </p>
            </div>
            <div className="row">
              <span id="subjectLabel" data-l10n-id="pdfjs-document-properties-subject">
                Subject:
              </span>
              <p id="subjectField" aria-labelledby="subjectLabel">
                -
              </p>
            </div>
            <div className="row">
              <span id="keywordsLabel" data-l10n-id="pdfjs-document-properties-keywords">
                Keywords:
              </span>
              <p id="keywordsField" aria-labelledby="keywordsLabel">
                -
              </p>
            </div>
            <div className="row">
              <span id="creationDateLabel" data-l10n-id="pdfjs-document-properties-creation-date">
                Creation Date:
              </span>
              <p id="creationDateField" aria-labelledby="creationDateLabel">
                -
              </p>
            </div>
            <div className="row">
              <span id="modificationDateLabel" data-l10n-id="pdfjs-document-properties-modification-date">
                Modification Date:
              </span>
              <p id="modificationDateField" aria-labelledby="modificationDateLabel">
                -
              </p>
            </div>
            <div className="row">
              <span id="creatorLabel" data-l10n-id="pdfjs-document-properties-creator">
                Creator:
              </span>
              <p id="creatorField" aria-labelledby="creatorLabel">
                -
              </p>
            </div>
            <div className="separator"></div>
            <div className="row">
              <span id="producerLabel" data-l10n-id="pdfjs-document-properties-producer">
                PDF Producer:
              </span>
              <p id="producerField" aria-labelledby="producerLabel">
                -
              </p>
            </div>
            <div className="row">
              <span id="versionLabel" data-l10n-id="pdfjs-document-properties-version">
                PDF Version:
              </span>
              <p id="versionField" aria-labelledby="versionLabel">
                -
              </p>
            </div>
            <div className="row">
              <span id="pageCountLabel" data-l10n-id="pdfjs-document-properties-page-count">
                Page Count:
              </span>
              <p id="pageCountField" aria-labelledby="pageCountLabel">
                -
              </p>
            </div>
            <div className="row">
              <span id="pageSizeLabel" data-l10n-id="pdfjs-document-properties-page-size">
                Page Size:
              </span>
              <p id="pageSizeField" aria-labelledby="pageSizeLabel">
                -
              </p>
            </div>
            <div className="separator"></div>
            <div className="row">
              <span id="linearizedLabel" data-l10n-id="pdfjs-document-properties-linearized">
                Fast Web View:
              </span>
              <p id="linearizedField" aria-labelledby="linearizedLabel">
                -
              </p>
            </div>
            <div className="buttonRow">
              <button id="documentPropertiesClose" className="dialogButton">
                <span data-l10n-id="pdfjs-document-properties-close-button">Close</span>
              </button>
            </div>
          </dialog>
          <dialog
            className="dialog altText"
            id="altTextDialog"
            aria-labelledby="dialogLabel"
            aria-describedby="dialogDescription"
          >
            <div id="altTextContainer" className="mainContainer">
              <div id="overallDescription">
                <span id="dialogLabel" data-l10n-id="pdfjs-editor-alt-text-dialog-label" className="title">
                  Choose an option
                </span>
                <span id="dialogDescription" data-l10n-id="pdfjs-editor-alt-text-dialog-description">
                  Alt text (alternative text) helps when people can’t see the image or when it doesn’t load.
                </span>
              </div>
              <div id="addDescription">
                <div className="radio">
                  <div className="radioButton">
                    <input
                      type="radio"
                      id="descriptionButton"
                      name="altTextOption"
                      tabIndex={0}
                      aria-describedby="descriptionAreaLabel"
                    />
                    <label htmlFor="descriptionButton" data-l10n-id="pdfjs-editor-alt-text-add-description-label">
                      Add a description
                    </label>
                  </div>
                  <div className="radioLabel">
                    <span id="descriptionAreaLabel" data-l10n-id="pdfjs-editor-alt-text-add-description-description">
                      Aim for 1-2 sentences that describe the subject, setting, or actions.
                    </span>
                  </div>
                </div>
                <div className="descriptionArea">
                  <textarea
                    id="descriptionTextarea"
                    placeholder="For example, “A young man sits down at a table to eat a meal”"
                    aria-labelledby="descriptionAreaLabel"
                    data-l10n-id="pdfjs-editor-alt-text-textarea"
                    tabIndex={0}
                  />
                </div>
              </div>
              <div id="markAsDecorative">
                <div className="radio">
                  <div className="radioButton">
                    <input type="radio" id="decorativeButton" name="altTextOption" aria-describedby="decorativeLabel" />
                    <label htmlFor="decorativeButton" data-l10n-id="pdfjs-editor-alt-text-mark-decorative-label">
                      Mark as decorative
                    </label>
                  </div>
                  <div className="radioLabel">
                    <span id="decorativeLabel" data-l10n-id="pdfjs-editor-alt-text-mark-decorative-description">
                      This is used for ornamental images, like borders or watermarks.
                    </span>
                  </div>
                </div>
              </div>
              <div id="buttons">
                <button id="altTextCancel" className="secondaryButton" tabIndex={0}>
                  <span data-l10n-id="pdfjs-editor-alt-text-cancel-button">Cancel</span>
                </button>
                <button id="altTextSave" className="primaryButton" tabIndex={0}>
                  <span data-l10n-id="pdfjs-editor-alt-text-save-button">Save</span>
                </button>
              </div>
            </div>
          </dialog>
          <dialog id="printServiceDialog" style={{ minWidth: 200 }}>
            <div className="row">
              <span data-l10n-id="pdfjs-print-progress-message">Preparing document for printing…</span>
            </div>
            <div className="row">
              <progress value="0" max="100"></progress>
              <span
                data-l10n-id="pdfjs-print-progress-percent"
                data-l10n-args='{ "progress": 0 }'
                className="relative-progress"
              >
                0%
              </span>
            </div>
            <div className="buttonRow">
              <button id="printCancel" className="dialogButton">
                <span data-l10n-id="pdfjs-print-progress-close-button">Cancel</span>
              </button>
            </div>
          </dialog>
        </div>
      </div>
      <div id="printContainer"></div>
    </Box>
  );
};

export const PDFPreviewer = memo(PDFPreviewerBase);
