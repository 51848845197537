import { memo, useState } from 'react';
import { ActionIcon, TextInput } from '@mantine/core';
import { IconArrowUp } from '@tabler/icons-react';

export interface ChatInputProps {
  onSendMessage: (message: string) => void;
  loading?: boolean;
  disabled?: boolean;
}

const ChatInputBase = ({ onSendMessage, disabled, loading }: ChatInputProps) => {
  const [message, setMessage] = useState('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!message.trim() || disabled) return;

    onSendMessage(message);
    setMessage('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        placeholder="Type your message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        disabled={disabled}
        rightSection={
          <ActionIcon variant="filled" size={20} color="brand" radius="50%" onClick={handleSubmit} loading={loading}>
            <IconArrowUp size={14} />
          </ActionIcon>
        }
        sx={{ flex: 1 }}
      />
    </form>
  );
};

export const ChatInput = memo(ChatInputBase);
