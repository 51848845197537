import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useAppNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToSettings = useCallback(() => {
    navigate('/settings');
  }, [navigate]);

  const navigateToChat = useCallback(
    (conversationId?: string) => {
      navigate({ pathname: conversationId ? `/oldChat/${conversationId}` : '/chat', search: location.search });
    },
    [navigate, location],
  );

  return { navigate, navigateToChat, navigateToSettings };
};
