import { useCurrentUser, useCurrentUserOrganization } from '@/pageAI/hooks/users/useCurrentUser';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import { ChatBubble } from '../../chat/ChatBubble';

interface ClientPageSharedLayoutProps {}

const ClientPageSharedLayoutBase = (props: ClientPageSharedLayoutProps) => {
  const { currentUser } = useCurrentUser();
  const organization = useCurrentUserOrganization();

  return (
    <>
      {currentUser && organization && <ChatBubble userId={currentUser.id} organizationId={organization.id} />}

      <Outlet />
    </>
  );
};

export const ClientPageSharedLayout = memo(ClientPageSharedLayoutBase);
