import { memo, useMemo } from 'react';

import { ConditionAllCodesheetsProps } from './ConditionAllCodesheets.types';

import { ConditionSummaryEntries } from '../ConditionSummaryEntries';
import { ConditionSummaryEntryWithSubEntries } from '@/pageAI/services/medicalConditions';

const ConditionAllCodesheetsBase = ({ condition }: ConditionAllCodesheetsProps) => {
  const codesheetEntries = useMemo(() => {
    const entries: ConditionSummaryEntryWithSubEntries[] = [];

    if (condition.latestCodesheet?.data) {
      entries.push(condition.latestCodesheet.data);

      if (condition.allCodesheets?.data) {
        entries[0].subEntries = condition.allCodesheets.data.filter(
          (codesheetEntry) => codesheetEntry.id !== condition.latestCodesheet?.data?.id,
        );
      }
    }

    return entries;
  }, [condition]);

  if (!condition.latestCodesheet?.data) return;

  return (
    <ConditionSummaryEntries
      condition={condition}
      entries={codesheetEntries}
      sectionKey="proceduralHistory.latestCodesheet"
      sectionField="latestCodesheet"
    />
  );
};

export const ConditionAllCodesheets = memo(ConditionAllCodesheetsBase);
