import { useMutation } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { updateFileMetadataFromFileNameMutation } from '@/pageAI/api';
import { UpdateFileMetadataFromFileNameInput, UpdateFileMetadataFromFileNameMutation } from '@/pageAI/gql/graphql';

export const useUpdateFileMetadataFromFileName = () => {
  const { request } = useGraphQLRequest();

  const { mutateAsync, isLoading, isError, data } = useMutation<
    UpdateFileMetadataFromFileNameMutation,
    Error,
    UpdateFileMetadataFromFileNameInput
  >({
    mutationFn: (input: UpdateFileMetadataFromFileNameInput) =>
      request(updateFileMetadataFromFileNameMutation, { input }),
  });

  return {
    updateFileMetadataFromFileName: mutateAsync,
    isLoading,
    isError,
    data,
  };
};
