import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { screenshotToTextQuery } from '@/pageAI/api';

export const useScreenshotToText = () => {
  const queryClient = useQueryClient();
  const { request } = useGraphQLRequest();

  const screenshotToText = useCallback(
    async (base64ImageData: string) => {
      const response = await queryClient.fetchQuery(['screenshotToText', base64ImageData], () =>
        request(screenshotToTextQuery, { b64EncodedImage: base64ImageData }),
      );

      return response.screenshotToText;
    },
    [queryClient, request],
  );

  return { screenshotToText };
};
