import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { ActionIcon, Button, Flex, Text } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import { AddEventToUnknownConditionDropdownProps } from './ConditionDropdown.types';
import { MedicalConditionMenu } from '../MedicalConditionMenu';
import { useClientMedicalConditions } from '@/pageAI/hooks/clients/useClientMedicalConditions';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { SimpleCondition } from '@/pageAI/@types/summaries';
import { selectedSimpleConditionSelector, useUnifiedStore } from '@/pageAI/states/unified';
import { isArchivedCondition } from '@/pageAI/services/medicalConditions';

const ConditionDropdownBase = ({
  onConfirm,
  onClose,
  multiple = true,
  noConfirm = false,
  maxWidth,
}: AddEventToUnknownConditionDropdownProps) => {
  const client = useSelectedClient(true);

  const selectedCondition = useUnifiedStore(selectedSimpleConditionSelector);

  const [selectedConditionIds, setSelectedConditionIds] = useState<string[]>(() =>
    selectedCondition ? [selectedCondition.id] : [],
  );

  useEffect(() => {
    if (!selectedCondition) return;

    const selectedConditionMenuItemElement = document.querySelector(
      '.condition-dropdown .condition-item[data-current-condition="true"]',
    ) as HTMLDivElement | null;

    if (!selectedConditionMenuItemElement) return;

    requestIdleCallback(() => selectedConditionMenuItemElement.scrollIntoView({ block: 'center' }));
  }, [selectedCondition]);

  const { medicalConditions, isLoading, isError } = useClientMedicalConditions({
    clientId: client.id,
    autoSelect: false,
  });

  const filteredConditions = useMemo(
    () => medicalConditions.filter((condition) => !isArchivedCondition(condition)),
    [medicalConditions],
  );

  const selectedConditions = useMemo(
    () => filteredConditions.filter((condition) => selectedConditionIds.includes(condition.id)),
    [filteredConditions, selectedConditionIds],
  );

  const handleSelect = useCallback(
    (condition: SimpleCondition) => {
      if (noConfirm) {
        onConfirm?.([condition]);
        onClose?.();

        return;
      }

      if (selectedConditionIds.includes(condition.id)) {
        setSelectedConditionIds(
          selectedConditionIds.filter((selectedConditionId) => selectedConditionId !== condition.id),
        );

        return;
      }

      setSelectedConditionIds([...selectedConditionIds, condition.id]);
    },
    [selectedConditionIds, noConfirm, onConfirm, onClose],
  );

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();

    onClose?.();
  };

  const handleConfirm = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (!selectedConditionIds.length) return;

    onConfirm?.(selectedConditions);
  };

  return (
    <Flex direction="column" onClick={(event) => event.stopPropagation()} className="condition-dropdown">
      <Flex align="center" justify="space-between" py="xs" px={12} gap="xs">
        <Text fw={600} color="dark.4" fz={noConfirm ? '0.875rem' : '1rem'}>
          {noConfirm ? 'Select condition' : 'Select conditions'}
          {!noConfirm && (
            <Text component="span" fz="0.75rem" fw={400} color="gray.6">
              &nbsp;({selectedConditionIds.length} selected)
            </Text>
          )}
        </Text>

        <ActionIcon size="xs" onClick={onClose}>
          <IconX size={14} />
        </ActionIcon>
      </Flex>

      <MedicalConditionMenu
        selected={selectedConditionIds}
        onSelect={handleSelect}
        medicalConditions={filteredConditions}
        loading={isLoading}
        maxHeight="240px"
        checkboxMode={multiple}
        maxWidth={maxWidth}
      />

      {!noConfirm && (
        <Flex
          align="center"
          justify="end"
          gap="xs"
          px={12}
          py="xs"
          sx={(theme) => ({
            borderTop: `1px solid ${theme.colors.gray[2]}`,
          })}
        >
          <Button variant="subtle" color="gray.7" size="xs" onClick={handleClose}>
            Close
          </Button>

          <Button size="xs" onClick={handleConfirm} disabled={!selectedConditionIds}>
            Confirm
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export const ConditionDropdown = memo(ConditionDropdownBase);
