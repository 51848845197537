import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';
import { getDuplicatedFilesQuery } from '@/pageAI/api';
import { truthy } from '@/shared/utils/boolean';
import { convertBrowserFileToAsset } from '@/shared/services/files';

const fetchCheckUploadFiles = async (
  request: GraphQLRequestFunction,
  clientId: string | undefined,
  fileNames: string[] | undefined,
) => {
  if (!clientId || !fileNames?.length) return Promise.resolve(null);

  const responseBody = await request(getDuplicatedFilesQuery, { clientId, fileNames });

  const duplicatedFiles =
    responseBody.client.checkFileDuplicates?.filter((fileCheck) => fileCheck?.isDuplicate).filter(truthy) || [];
  const newFiles =
    responseBody.client.checkFileDuplicates?.filter((fileCheck) => !fileCheck?.isDuplicate).filter(truthy) || [];

  return { duplicatedFiles, newFiles };
};

export const queryMap = {
  checkUploadFiles: {
    queryKey: (clientId?: string, fileNames?: string[]) => ['checkUploadFiles', clientId, fileNames],
    queryFn: fetchCheckUploadFiles,
  },
};

export type CheckUploadFilesQuery = Awaited<ReturnType<typeof queryMap.checkUploadFiles.queryFn>>;

export const useCheckUploadFiles = (clientId?: string, selectedFiles?: File[], enabled = true) => {
  const { request } = useGraphQLRequest();

  const fileNames = useMemo(() => selectedFiles?.map((file) => file.name), [selectedFiles]);

  const isQueryEnabled = useMemo(() => !!clientId && !!fileNames?.length && enabled, [clientId, fileNames, enabled]);

  const { data, isLoading, isError } = useQuery(
    queryMap.checkUploadFiles.queryKey(clientId, fileNames),
    () => queryMap.checkUploadFiles.queryFn(request, clientId, fileNames),
    {
      enabled: isQueryEnabled,
    },
  );

  const mappedResults = useMemo(() => {
    if (!data || !selectedFiles) return null;

    // Map the checked files back to original File objects
    const duplicatedFiles = selectedFiles.filter((file) =>
      data.duplicatedFiles.some((checkedFile) => checkedFile.fileName === file.name),
    );
    const newFiles = selectedFiles.filter((file) =>
      data.newFiles.some((checkedFile) => checkedFile.fileName === file.name),
    );

    // Convert to FileAssets
    const duplicatedFileAssets = duplicatedFiles.map((file) => convertBrowserFileToAsset(file));
    const newFileAssets = newFiles.map((file) => convertBrowserFileToAsset(file));

    return {
      checkResult: data,
      duplicatedFiles,
      newFiles,
      duplicatedFileAssets,
      newFileAssets,
    };
  }, [data, selectedFiles]);

  return {
    uploadFileCheck: mappedResults,
    isLoading: isLoading && isQueryEnabled,
    isError,
  };
};
