import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { ActionIcon, Button, Input, Tooltip } from '@mantine/core';
import { IconUpload } from '@tabler/icons-react';

import { UploadClientFileButtonProps } from './UploadClientFileButton.types';
import { ALLOWED_FILE_TYPES, UploadFileModal } from '@/shared/components/files/UploadFileModal';
import { UploadFileModal as OldUploadFileModal } from '@/shared/components/files/UploadFileModal/OldUploadFileModal';
import { getClientFiles } from '@/pageAI/services/clients';
import { useLayoutStore } from '@/shared/states/layout';
import { posthog } from '@/shared/plugins/posthog';
import { getPdfFilesOnly } from '@/shared/services/files';
import { useIncrementalUploadFeatureFlag } from '@/pageAI/hooks/featureFlags/useIncrementalUploadFeatureFlag';

const UploadClientFileButtonBase = ({ onUpload, client, compact, disabled }: UploadClientFileButtonProps) => {
  const isRightSidebarOpened = useLayoutStore((state) => state.isRightSidebarOpened);
  const { enabled: isIncrementalUploadEnabled } = useIncrementalUploadFeatureFlag();

  const [opened, { open, close }] = useDisclosure();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const { fileAssets } = useMemo(() => getClientFiles(client), [client]);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleChangeFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files?.length ? Array.from(event.target.files) : null;

    const pdfFiles = await getPdfFilesOnly(files);

    setSelectedFiles(pdfFiles);

    Promise.resolve().then(() => open());
  };

  const handleUpload = useCallback(
    (files: File[], generateSummaries = false) => {
      posthog.capture('[Files] Upload client files', {
        numberOfFiles: files.length,
        clientId: client.id,
        clientFirstName: client.firstName,
        clientLastName: client.lastName,
        autoGenerateSummaries: generateSummaries,
      });

      onUpload?.(files, generateSummaries);

      setSelectedFiles([]);
    },
    [onUpload, client],
  );

  return (
    <>
      {isRightSidebarOpened && compact ? (
        <Tooltip label="Upload files" withArrow withinPortal>
          <ActionIcon color="red" variant="filled" size="lg" onClick={handleClick} disabled={disabled}>
            <IconUpload size={16} />
          </ActionIcon>
        </Tooltip>
      ) : (
        <Button color="red" leftIcon={<IconUpload size={16} />} onClick={handleClick} disabled={disabled}>
          Upload files
        </Button>
      )}

      <Input
        ref={inputRef}
        accept={ALLOWED_FILE_TYPES.join(',')}
        type="file"
        multiple
        onChange={handleChangeFiles}
        sx={{ display: 'none' }}
      />

      {isIncrementalUploadEnabled ? (
        <UploadFileModal
          key={String(opened)}
          opened={opened}
          onClose={close}
          defaultDescription="Drop folder(s) or file(s) here or click to upload"
          onUpload={handleUpload}
          initialFiles={selectedFiles}
          existingFileAssets={fileAssets}
          hideOptions
          rejectable={false}
        />
      ) : (
        <OldUploadFileModal
          key={String(opened)}
          opened={opened}
          onClose={close}
          defaultDescription="Drop folder(s) or file(s) here or click to upload"
          onUpload={handleUpload}
          initialFiles={selectedFiles}
          existingFileAssets={fileAssets}
          hideOptions
          rejectable={false}
        />
      )}
    </>
  );
};

export const UploadClientFileButton = memo(UploadClientFileButtonBase);
