import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { UpdateConditionSummaryItemInput, UpdateConditionSummaryItemMutation } from '@/pageAI/gql/graphql';
import { updateConditionSummaryItemMutation } from '@/pageAI/api';
import { useUpdateCondition } from '../useUpdateCondition/useUpdateCondition';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { useTimelineEvent } from '../../caseTimeline/useTimelineEvent';
import { useUpdateTimelineEvent } from '../../caseTimeline/useUpdateTimelineEvent';
import { useSelectedClient } from '../../clients/useSelectedClient';

export const useUpdateConditionEntry = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();

  const client = useSelectedClient(true);

  const { updateConditionLocally } = useUpdateCondition(client.id);
  const { fetchTimelineEvent } = useTimelineEvent();
  const { updateTimelineEventLocally } = useUpdateTimelineEvent();

  const { mutateAsync: updateConditionEntryBase, isLoading: isUpdating } = useMutation<
    UpdateConditionSummaryItemMutation,
    Error,
    UpdateConditionSummaryItemInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(updateConditionSummaryItemMutation, { input });

      return responseBody;
    },
  });

  const updateConditionEntry = useCallback(
    async (input: UpdateConditionSummaryItemInput) => {
      try {
        const response = await updateConditionEntryBase(input);

        if (!response.updateConditionSummaryItem.condition) return;

        updateConditionLocally(input.conditionId, response.updateConditionSummaryItem.condition);

        notify('Edit successful', 'Events updated in condition summary', 'brand');

        const updatedEventIds = response.updateConditionSummaryItem.updatedEventIds;

        if (updatedEventIds.length) {
          updatedEventIds.forEach((eventId) => {
            fetchTimelineEvent(eventId).then((timelineEvent) => {
              if (!timelineEvent) return;

              updateTimelineEventLocally(client, timelineEvent);
            });
          });
        }

        return response.updateConditionSummaryItem;
      } catch (error) {
        console.error(error);

        notify('Edit failed', 'Failed to edit event(s) in condition summary');
      }
    },
    [updateConditionLocally, client, notify, updateConditionEntryBase, fetchTimelineEvent, updateTimelineEventLocally],
  );

  return { updateConditionEntry, isUpdating };
};
