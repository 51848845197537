import { memo } from 'react';
import { Flex, Text } from '@mantine/core';

import { MarkdownRenderer } from '@/shared/components/common/markdown/MarkdownRenderer';
import { BlinkingCursor } from '@/shared/components/common/BlinkingCursor';
import { BotMessageAvatar } from '@/shared/components/oldChat/BotMessageAvatar';
import { ChatMessage } from '@/pageAI/hooks/chat/useChatHistory';

interface BotMessageProps {
  message?: ChatMessage;
  loading?: boolean;
}

const BotMessageBase = ({ message, loading = false }: BotMessageProps) => {
  return (
    <Flex
      direction="column"
      className="chat-bot-message"
      sx={{
        '&:hover': {
          '.chat-bot-message__actions': { opacity: 1, pointerEvents: 'all' },
        },
      }}
    >
      <Flex align="center" gap="xs">
        <BotMessageAvatar />

        <Text fz="0.875rem" color="dark" sx={{ 'p:first-of-type': { margin: 0 } }}>
          {loading && <BlinkingCursor />}

          {message && <MarkdownRenderer content={message.message} />}
        </Text>
      </Flex>
    </Flex>
  );
};

export const BotMessage = memo(BotMessageBase);
