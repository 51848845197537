import { memo } from 'react';
import { Navbar, Flex, Badge } from '@mantine/core';
import { IconSettings } from '@tabler/icons-react';

import { MainLink, MainLinks } from '../MainLinks';
import { ProfileMenu } from '@/shared/components/users/ProfileMenu';
import PageLogoCircle from '@/pageAI/icons/page-logo-circle.svg?react';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import { HelpButton } from '@/pageAI/components/help/HelpButton';
import { IS_DEV_TEST, IS_LOCALHOST, IS_STAGING } from '@/shared/config';
import { useNotesStore } from '@/pageAI/states/notes';

const PageAILeftSidebarBase = () => {
  const { currentUser } = useCurrentUser();

  // TODO: Refactor this
  const isPrinting = useNotesStore((state) => state.isPrinting);

  const renderLogoBadge = () => {
    if (IS_LOCALHOST) {
      return (
        <Badge pos="absolute" size="xs" color="violet.7" variant="filled" bottom={-8}>
          Local
        </Badge>
      );
    }

    if (IS_DEV_TEST) {
      return (
        <Badge pos="absolute" size="xs" color="red.7" variant="filled" bottom={-8}>
          Dev Test
        </Badge>
      );
    }

    if (IS_STAGING) {
      return (
        <Badge pos="absolute" size="xs" color="indigo.7" variant="filled" bottom={-8}>
          Staging
        </Badge>
      );
    }

    return null;
  };

  if (isPrinting) return null;

  return (
    <Navbar
      className="unprintable"
      width={{ base: 64 }}
      p="xs"
      pt={8}
      sx={(theme) => ({
        backgroundColor: theme.colors.dark[5],
        borderRadius: 0,
        border: 'none',
      })}
    >
      <Flex
        data-testid="app-logo"
        align="center"
        justify="center"
        pos="relative"
        mb={24}
        sx={{ svg: { circle: { display: 'none' } } }}
      >
        <PageLogoCircle width={48} height={48} />

        {renderLogoBadge()}
      </Flex>

      <MainLinks />

      <Flex sx={{ flexGrow: 1 }} />

      <Flex direction="column" gap={4}>
        <HelpButton />

        <MainLink icon={<IconSettings size={24} />} label="Settings" path="/settings" pathname="/settings" />

        <Flex align="center" justify="center" my="xs">
          <ProfileMenu avatarUrl={currentUser?.avatarUrl} />
        </Flex>
      </Flex>
    </Navbar>
  );
};

export const PageAILeftSidebar = memo(PageAILeftSidebarBase);
