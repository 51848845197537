import { memo, useCallback } from 'react';
import { Flex, Text } from '@mantine/core';

import {
  isSingleSummaryOrDetailType,
  TIMELINE_EVENT_DETAILS_TYPE_DISPLAY_TEXT_MAPPING,
  TIMELINE_EVENT_SUMMARIES_TYPE_DISPLAY_TEXT_MAPPING,
} from '@/pageAI/services/caseTimeline';
import { TimelineEditorRow } from '../TimelineEditorRow';
import { snakeCaseToDisplayText } from '@/shared/utils/string';
import { UnderlinedTextButton } from '@/shared/designSystem/UnderlinedTextButton';
import { IconPlus } from '@tabler/icons-react';

interface TimelineEditorGroupProps {
  contents: string[];
  contentType: string;
  onChange: (content: string, contentType: string, index: number) => void;
  onAdd?: (contentType: string) => void;
  onRemove: (contentType: string, index: number) => void;
  isFirstGroup?: boolean;
}

const MIXED_DISPLAY_MAPPING: Record<string, string> = {
  ...TIMELINE_EVENT_SUMMARIES_TYPE_DISPLAY_TEXT_MAPPING,
  ...TIMELINE_EVENT_DETAILS_TYPE_DISPLAY_TEXT_MAPPING,
};

const TimelineEditorGroupBase = ({
  contents,
  contentType,
  onChange,
  onAdd,
  onRemove,
  isFirstGroup = false,
}: TimelineEditorGroupProps) => {
  const handleChange = useCallback(
    (data: string, index: number) => {
      onChange(data, contentType, index);
    },
    [onChange, contentType],
  );

  const handleAdd = useCallback(() => {
    onAdd?.(contentType);
  }, [onAdd, contentType]);

  const handleRemove = useCallback(
    (index: number) => {
      onRemove(contentType, index);
    },
    [onRemove, contentType],
  );

  const isSingle = contents.length === 1 && isSingleSummaryOrDetailType(contentType);

  return (
    <Flex direction="column" gap={4} sx={{ width: '100%' }}>
      <Text fz="0.875rem" fw={500} color="dark">
        {MIXED_DISPLAY_MAPPING[contentType] || snakeCaseToDisplayText(contentType)}
      </Text>

      {contents.map((content, index) => {
        return (
          <TimelineEditorRow
            key={index}
            data={content}
            index={index}
            onChange={handleChange}
            onRemove={contents.length > 1 ? handleRemove : undefined}
            hideNumbering={isSingle}
            isFirstGroup={isFirstGroup}
          />
        );
      })}

      {!isSingle && onAdd && (
        <Flex mt={6} ml={-6} mb={4}>
          <UnderlinedTextButton sx={{ fontSize: '0.75rem', marginLeft: 6 }} color="dark.4" onClick={handleAdd}>
            <IconPlus size={14} /> Add another entry
          </UnderlinedTextButton>
        </Flex>
      )}
    </Flex>
  );
};

export const TimelineEditorGroup = memo(TimelineEditorGroupBase);
