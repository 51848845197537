import { Accordion, Alert, Flex, Text } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

import { AssetTable } from '@/shared/components/files/AssetTable';
import { FileAsset } from '@/shared/@types';

interface DuplicatedFilesAlertProps {
  duplicatedFileAssets: FileAsset[];
  newFileAssets?: FileAsset[];
  totalSelectedFiles: number;
}

export const DuplicatedFilesAlert: React.FC<DuplicatedFilesAlertProps> = ({
  duplicatedFileAssets,
  newFileAssets,
  totalSelectedFiles,
}) => {
  const renderFileAssets = (fileAssets: FileAsset[], isDuplicated = false) => {
    return (
      <Accordion
        mt="xs"
        defaultValue=""
        sx={(theme) => ({
          border: `1px solid ${theme.colors.gray[3]}`,
          borderRadius: 4,
          '.ghost-Accordion-content': {
            padding: 0,
          },
        })}
      >
        <Accordion.Item value={`listOf${isDuplicated ? 'Duplicate' : 'New'}Files`} sx={{ border: 'none' }}>
          <Accordion.Control
            fz="0.875rem"
            sx={{
              paddingLeft: 10,
              paddingRight: 4,
              '.ghost-Accordion-label': {
                fontWeight: 500,
              },
            }}
          >
            List of {isDuplicated ? 'duplicate' : 'new'} files ({fileAssets.length})
          </Accordion.Control>

          <Accordion.Panel>
            <AssetTable
              key={fileAssets.length}
              assetItems={fileAssets}
              hiddenColumns={['Size', 'Type', 'Received Date', 'Status', '']}
              colgroup={
                <colgroup>
                  <col style={{ width: '3%' }} />
                  <col style={{ width: '97%' }} />
                </colgroup>
              }
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    );
  };

  if (!duplicatedFileAssets.length) return null;

  return (
    <Alert
      title={
        <Flex align="center" gap={4}>
          <IconAlertCircle size="1rem" />
          Duplicate files detected!
        </Flex>
      }
      color="red"
      variant="outline"
    >
      <Flex direction="column" gap="xs">
        <Text fz="0.875rem">
          {newFileAssets?.length
            ? `You have selected ${duplicatedFileAssets.length} duplicate files (which will not be reuploaded) and ${newFileAssets.length} new files. Both are listed below:`
            : `All selected files have already been uploaded to this client and will not be uploaded again. Duplicate files
          are listed below:`}
        </Text>
      </Flex>

      {renderFileAssets(duplicatedFileAssets, true)}

      {newFileAssets?.length ? renderFileAssets(newFileAssets, false) : null}
    </Alert>
  );
};
