import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { Accordion, Box } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { ConditionSummaryReferencedFileInfo } from '@/pageAI/gql/graphql';
import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { MedicalConditionSummaryEntry } from '../../MedicalConditionSummaryEntry';
import {
  isPrintingConditionSummarySelector,
  setSummaryHighlighted,
  summaryTabHighlightedSelector,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import {
  dedupeConditionSummaryEntries,
  ConditionSummaryEntryWithSubEntries,
  getConditionEntryKey,
} from '@/pageAI/services/medicalConditions';
import { UnderlinedTextButton } from '@/shared/designSystem/UnderlinedTextButton';

export interface ConditionSummaryEntriesProps {
  entries: (ConditionSummaryReferencedFileInfo | ConditionSummaryEntryWithSubEntries)[];
  sectionKey: string;
  sectionField: string;
  condition: MedicalConditionItem;
  keywords?: string[];
}

const ConditionSummaryEntriesBase = ({
  entries,
  sectionKey,
  sectionField,
  condition,
  keywords = [],
}: ConditionSummaryEntriesProps) => {
  const highlighted = useUnifiedStore(summaryTabHighlightedSelector);
  const isPrinting = useUnifiedStore(isPrintingConditionSummarySelector);

  const [closedMapping, setClosedMapping] = useState<Record<string, boolean>>({});

  const handleEntryReferenceClick = useCallback((entry: ConditionSummaryReferencedFileInfo, entryKey: string) => {
    setSummaryHighlighted(entryKey);
  }, []);

  const dedupedEntries = useMemo(() => dedupeConditionSummaryEntries(entries), [entries]);

  const renderEntry = (entry: ConditionSummaryEntryWithSubEntries, entryIndex: number) => {
    const entryKey = getConditionEntryKey(sectionKey, sectionField, entryIndex);
    const closed = closedMapping[entryKey];

    return (
      <>
        <MedicalConditionSummaryEntry
          medicalCondition={condition}
          entryKey={entryKey}
          entry={entry}
          sectionField={sectionField}
          isHighlighted={entryKey === highlighted}
          onReferenceClick={handleEntryReferenceClick}
          keywords={keywords}
        />

        {!!entry.subEntries?.length && !isPrinting && (
          <>
            <UnderlinedTextButton
              sx={{ marginLeft: 6 }}
              onClick={() => setClosedMapping({ ...closedMapping, [entryKey]: !closed })}
            >
              {!closed ? (
                <>
                  Hide
                  <IconChevronUp size={14} />
                </>
              ) : (
                <>
                  See all
                  <IconChevronDown size={14} />
                </>
              )}
            </UnderlinedTextButton>

            <Accordion
              value={!closed || isPrinting ? 'default' : ''}
              sx={{
                '.ghost-Accordion-content': {
                  padding: 0,
                },
              }}
            >
              <Accordion.Item
                value="default"
                key="default"
                sx={{
                  border: 'none',
                  ul: {
                    li: {
                      fontSize: '0.875rem',
                    },
                  },
                }}
              >
                <Accordion.Panel>
                  <ul>
                    {entry.subEntries.map((subEntry, subEntryIndex) => {
                      const subEntryKey = [entryKey, subEntryIndex].join('.');

                      return (
                        <li key={subEntryKey}>
                          <MedicalConditionSummaryEntry
                            medicalCondition={condition}
                            entryKey={subEntryKey}
                            entry={subEntry}
                            sectionField={sectionField}
                            isHighlighted={subEntryKey === highlighted}
                            onReferenceClick={() => handleEntryReferenceClick(subEntry, subEntryKey)}
                            keywords={keywords}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </>
        )}
      </>
    );
  };

  if (dedupedEntries.length === 1) {
    return (
      <Box
        sx={{
          width: '100%',
        }}
      >
        {renderEntry(dedupedEntries[0], 0)}
      </Box>
    );
  }

  return (
    <ol>
      {dedupedEntries.map((entry, entryIndex) => {
        return <li key={entry.id}>{renderEntry(entry, entryIndex)}</li>;
      })}
    </ol>
  );
};

export const ConditionSummaryEntries = memo(ConditionSummaryEntriesBase);
