import React, { memo } from 'react';
import { ActionIcon, Flex, Paper, Switch } from '@mantine/core';
import { IconArrowRight, IconArrowUp } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';

import { ChatBoxProps } from './ChatBox.types';
import { useCreateConversation } from '@/shared/hooks/oldChat/useCreateConversation';
import {
  resetChatContent,
  setChatContent,
  setIsWaitingForAnswer,
  toggleMessageRetrieverType,
  useChatStore,
} from '@/shared/states/chat';
import { RichTextInput } from '@/shared/components/common/RichTextInput';
import { useSendMessage } from '@/shared/hooks/oldChat/useSendMessage';
import { MessageRetrieverType } from '@/pageAI/gql/graphql';
import { useAppNavigate } from '@/shared/hooks/routes/useAppNavigate';

const ChatBoxBase: React.FC<ChatBoxProps> = ({
  sx,
  disabled,
  placeholder = 'Enter your question...',
  compact = false,
  shadow = 'md',
  onMessageSent,
  hideRetrieverTypeSwitch = false,
}) => {
  const { conversationId } = useParams();
  const { navigateToChat } = useAppNavigate();
  const { createConversation, isLoading: isCreatingConversation } = useCreateConversation();
  const { sendMessage, isLoading: isSendingMessage } = useSendMessage();
  const { attachmentIds, chatContent, isWaitingForAnswer, retrieverType } = useChatStore();

  const isLoading = isCreatingConversation || isSendingMessage;

  const handleSubmit = async () => {
    if (!chatContent) return;

    if (!conversationId) {
      const response = await createConversation({ name: 'New conversation' });

      navigateToChat(response.createConversation.id);

      return;
    }

    sendMessage(
      {
        conversationId,
        content: chatContent,
        attachments: attachmentIds?.map((attachmentId) => ({ fileId: attachmentId })),
        retrieverType,
      },
      {
        onSuccess: (data) => {
          onMessageSent?.(data.sendMessage);
        },
      },
    );

    resetChatContent();
    setIsWaitingForAnswer(true);
  };

  const handleChatBoxChange = (newTextValue: string) => {
    setChatContent(newTextValue);
  };

  return (
    <Paper
      shadow={shadow}
      withBorder
      sx={{
        width: '100%',
        display: 'flex',
        gap: 8,
        position: 'relative',
        ...(compact
          ? {
              padding: '16px 108px 16px 16px',
              flexDirection: 'row',
              minHeight: 56,
            }
          : {
              flexDirection: 'column',
              padding: 16,
            }),
        ...sx,
      }}
    >
      <RichTextInput
        sx={compact ? { minHeight: 'fit-content' } : {}}
        placeholder={placeholder}
        disabled={isLoading}
        textValue={chatContent}
        onChange={handleChatBoxChange}
        onEnter={handleSubmit}
      />

      <Flex align="center" gap="xs" justify="space-between">
        <Flex align="center" gap="xs" sx={compact ? { position: 'absolute', right: 16, bottom: 12 } : {}}>
          {!hideRetrieverTypeSwitch && (
            <Switch
              size="md"
              offLabel="WEB"
              onLabel="DOCS"
              checked={retrieverType === MessageRetrieverType.Docs}
              onChange={toggleMessageRetrieverType}
              disabled={isLoading || isWaitingForAnswer || disabled}
            />
          )}

          <ActionIcon
            variant="filled"
            color="brand"
            radius="50%"
            onClick={handleSubmit}
            loading={isLoading}
            disabled={isWaitingForAnswer || disabled}
          >
            {compact ? <IconArrowUp size={16} /> : <IconArrowRight size={16} />}
          </ActionIcon>
        </Flex>
      </Flex>
    </Paper>
  );
};

export const ChatBox = memo(ChatBoxBase);
