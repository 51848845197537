import { useEffect } from 'react';
import { useKeyPress } from 'ahooks';
import { VirtuosoHandle } from 'react-virtuoso';
import { useSearchParams } from 'react-router-dom';

import { CaseTimelineEvent } from '@/pageAI/@types/summaries';
import {
  getTimelineEventElementId,
  highlightTimelineEventPubsub,
  scrollToTimelineEvent,
} from '@/pageAI/services/caseTimeline';
import { isInViewport } from '@/shared/lib/dom';
import { useTimelineReferenceHighlightStore } from '@/pageAI/states/timelineReferenceHighlights';
import { keys } from '@/shared/utils/object';

export const useHighlightTimelineEvent = (
  timelineEventGroups: [string, CaseTimelineEvent[]][],
  virtualizer: VirtuosoHandle | null,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!virtualizer) return;

    const unsubscribe = highlightTimelineEventPubsub.subscribe(({ event, shouldOpenReference, prefix }) => {
      if (prefix) return;

      searchParams.set('eventId', event.id);
      setSearchParams(searchParams);

      const timelineEventElement = document.getElementById(getTimelineEventElementId(event.id, prefix));

      const isVisible = timelineEventElement && isInViewport(timelineEventElement);

      if (!isVisible && virtualizer) {
        const virtualizerIndex = timelineEventGroups.findIndex(([date]) => date === event.date);

        if (virtualizerIndex > -1) {
          virtualizer?.scrollToIndex({
            index: virtualizerIndex,
            align: 'start',
          });
        }
      }

      scrollToTimelineEvent(event, shouldOpenReference, true, prefix);
    });

    return unsubscribe;
  }, [timelineEventGroups, virtualizer, searchParams, setSearchParams]);
};

export const useTimelineKeyboardShortcuts = (sortedCaseTimeline: CaseTimelineEvent[]) => {
  const highlightedEventMapping = useTimelineReferenceHighlightStore((state) => state.highlightedEventMapping);
  const firstHighlightedEventId = keys(highlightedEventMapping)[0];

  const navigateToNext = (isNext: boolean) => {
    const highlightedEventIndex = sortedCaseTimeline.findIndex((event) => event.id === firstHighlightedEventId);

    if (highlightedEventIndex === -1) return;

    const nextEvent = sortedCaseTimeline[highlightedEventIndex + (isNext ? 1 : -1)];

    if (!nextEvent) return;

    highlightTimelineEventPubsub.publish({ event: nextEvent, shouldOpenReference: true, hideHighlightEffect: true });
  };

  useKeyPress(['shift.uparrow'], () => navigateToNext(false));
  useKeyPress(['shift.downarrow'], () => navigateToNext(true));
};
