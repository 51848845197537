import { memo, useEffect, useRef } from 'react';
import { Box, ScrollArea } from '@mantine/core';

import { ChatMessage } from '@/pageAI/hooks/chat/useChatHistory/useChatHistory';
import { UserMessage } from '../UserMessage';
import { BotMessage } from '../BotMessage';

interface ChatMessagesProps {
  messages: ChatMessage[];
  isWaitingForAnswer?: boolean;
}

const ChatMessagesBase = ({ messages, isWaitingForAnswer = false }: ChatMessagesProps) => {
  const viewport = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (viewport.current) {
      viewport.current.scrollTo({ top: viewport.current.scrollHeight, behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <ScrollArea.Autosize mah="400px" viewportRef={viewport} sx={{ flex: 1, minHeight: 400 }}>
      <Box py="md">
        {messages.map((message) => (
          <Box key={message.id} mb="md" px="xs" sx={{ overflow: 'hidden' }}>
            {message.role === 'human' ? <UserMessage message={message} /> : <BotMessage message={message} />}
          </Box>
        ))}

        {isWaitingForAnswer && (
          <Box mb="md" px="xs">
            <BotMessage loading />
          </Box>
        )}
      </Box>
    </ScrollArea.Autosize>
  );
};

export const ChatMessages = memo(ChatMessagesBase);
