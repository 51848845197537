import { capitalize, escapeRegExp } from 'lodash-es';
import removeMarkdown from 'markdown-to-text';
import { removeStopwords } from 'stopword';

export const removeAllSpaces = (text: string) => {
  return text.replace(/\s/g, '');
};

export const uncapitalize = (text: string) => {
  return `${text[0].toLowerCase()}${text.slice(1)}`;
};

export const replaceSubstring = (originalString: string, start: number, end: number, newSubstring: string) => {
  return originalString.slice(0, start) + newSubstring + originalString.slice(end);
};

// Create a function to filter out objects with similar names
export const filterSimilarNames = (arr: { name: string; value: string }[]) => {
  const uniqueNames = new Set();
  return arr.filter((obj) => {
    if (!uniqueNames.has(obj.name)) {
      uniqueNames.add(obj.name);
      return true;
    }
    return false;
  });
};

export const separateTextByLineBreaks = (text: string) => {
  return text.replace(/\n+/g, '\n').split('\n');
};

export const markdownToTextParts = (markdown: string) => {
  const textParts = separateTextByLineBreaks(markdown);

  return textParts.map((textPart) => removeMarkdown(textPart.trim()));
};

export const sortAlphabetically = (textA: string, textB: string) => {
  return textA.localeCompare(textB);
};

export const matchWords = (sourceText: string, textToMatch: string, matchWholeWords = true) => {
  const regex = matchWholeWords
    ? new RegExp(`\\b${escapeRegExp(textToMatch)}\\b`, 'i')
    : new RegExp(escapeRegExp(textToMatch), 'i');

  return regex.test(sourceText);
};

export const removeEnglishStopwords = (texts: string[]) => {
  return removeStopwords(texts);
};

export const singularOrPlural = (singular: string, plural: string) => (count?: number | null) => {
  return typeof count === 'number' && count > 1 ? plural : singular;
};

export const snakeCaseToDisplayText = (text: string) => {
  return text.split('_').map(capitalize).join(' ');
};

export const getDomainFromEmail = (email: string) => {
  return email.split('@')[1];
};

export const pascalCaseWithSpaces = (text: string) => {
  return text.replace(/([A-Z])/g, ' $1').trim();
};

export const extractPercentNumber = (text: string) => {
  return text.match(/(\d+(\.\d+)?)%/)?.[1];
};

export const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
};

export const removeLineBreaks = (text: string, replacer = '') => {
  return text.replace(/\n/g, replacer);
};
