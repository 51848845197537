import { memo } from 'react';
import { ActionIcon, Flex, Text, Tooltip } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';

import { BotMessageProps } from './BotMessage.types';
import { BotMessageSources } from '../BotMessageSources';
import { MarkdownRenderer } from '@/shared/components/common/markdown/MarkdownRenderer';
import { BlinkingCursor } from '@/shared/components/common/BlinkingCursor';
import { CopyButton } from '@/shared/components/common/CopyButton';
import { BotMessageAvatar } from '@/shared/components/oldChat/BotMessageAvatar';

const BotMessageBase: React.FC<BotMessageProps> = ({ message, canRegenerate, onRegenerate }) => {
  return (
    <Flex
      direction="column"
      className="chat-bot-message"
      sx={{
        '&:hover': {
          '.chat-bot-message__actions': { opacity: 1, pointerEvents: 'all' },
        },
      }}
    >
      <Flex align="center" gap="xs">
        <BotMessageAvatar />

        <Text fz="0.875rem" color="dark" sx={{ 'p:first-of-type': { margin: 0 } }}>
          {!message.content.trim().length && <BlinkingCursor />}

          <MarkdownRenderer content={message.content} />
        </Text>
      </Flex>

      <Flex sx={{ marginLeft: 40 }} direction="column" gap={4}>
        <BotMessageSources message={message} />

        <Flex
          className="chat-bot-message__actions"
          align="center"
          sx={{
            opacity: 0,
            transition: 'all 0.1s',
            pointerEvents: 'none',
          }}
          gap={4}
        >
          <CopyButton value={message.content} tooltip="Copy as Markdown" size="xs" />

          <Tooltip label="Regenerate answer">
            <ActionIcon onClick={onRegenerate} size="xs" disabled={!canRegenerate}>
              <IconRefresh size={16} />
            </ActionIcon>
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const BotMessage = memo(BotMessageBase);
