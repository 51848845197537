import { memo, useMemo, useState } from 'react';
import { Button, Checkbox, Flex, Modal } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';

import { FileDropzone } from '@/shared/components/files/FileDropzone';
import { UploadFileModalProps } from './UploadFileModal.types';
import { getPdfFilesOnly } from '@/shared/services/files';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { useCheckUploadFiles } from '@/pageAI/hooks/files/useDuplicatedFiles';
import { DuplicatedFilesAlert } from '@/pageAI/components/files/alerts/DuplicatedFilesAlert';
import { useUpdateFileMetadataFromFileName } from '@/pageAI/hooks/files/useUpdateFileMetadataFromFileName';
import { FileDuplicateNextAction } from '@/pageAI/gql/graphql';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { getClientFiles } from '@/pageAI/services/clients';

export const ALLOWED_FILE_TYPES = ['application/pdf', 'application/zip'];

const UploadFileModalBase: React.FC<UploadFileModalProps> = ({
  onClose,
  onUpload,
  initialFiles = [],
  defaultDescription = 'Drop folder(s) or file(s) here or click to upload',
  opened = false,
  rejectable = true,
}) => {
  const { notify } = useNotifications();
  const queryClient = useQueryClient();

  const client = useSelectedClient();
  const { fileAssets } = useMemo(() => (client ? getClientFiles(client) : { fileAssets: [] }), [client]);
  const shouldCheckUploadFiles = !!fileAssets.length;

  const [selectedFiles, setSelectedFiles] = useState<File[]>(initialFiles);
  const [automaticallyGenerateSummaries, setAutomaticallyGenerateSummaries] = useLocalStorage({
    defaultValue: false,
    key: 'automaticallyGenerateSummariesAfterUpload',
  });
  const {
    uploadFileCheck,
    isLoading: isCheckingUploadFiles,
    isError: isErrorCheckingUploadFiles,
  } = useCheckUploadFiles(client?.id, selectedFiles, shouldCheckUploadFiles);
  const { updateFileMetadataFromFileName } = useUpdateFileMetadataFromFileName();
  const [isUpdatingFileMetadata, setIsUpdatingFileMetadata] = useState(false);

  const { checkResult, newFiles, duplicatedFileAssets, newFileAssets } = uploadFileCheck || {};

  const handleSelectFiles = async (files: File[]) => {
    const pdfFiles = await getPdfFilesOnly(files);

    setSelectedFiles(pdfFiles);
  };

  const filesThatNeedUpdate = useMemo(
    () => checkResult?.duplicatedFiles.filter((file) => file.nextAction === FileDuplicateNextAction.UpdateMetadata),
    [checkResult],
  );

  const handleUpload = async () => {
    if (filesThatNeedUpdate?.length && client?.id && shouldCheckUploadFiles) {
      setIsUpdatingFileMetadata(true);

      try {
        for (const file of filesThatNeedUpdate) {
          await updateFileMetadataFromFileName({
            clientId: client.id,
            fileName: file.fileName,
          });
        }

        await queryClient.refetchQueries(['client', client.id]);
      } catch (error) {
        console.error(error);

        notify('Error', 'Something went wrong when processing the files.');
      }

      setIsUpdatingFileMetadata(false);
    }

    const filesToUpload = shouldCheckUploadFiles ? newFiles : selectedFiles;

    if (filesToUpload?.length) onUpload?.(filesToUpload, automaticallyGenerateSummaries);

    setSelectedFiles([]);
    onClose();
  };

  const areThereDuplicatedFiles = !!duplicatedFileAssets?.length;
  const areThereNewFiles = !!newFileAssets?.length;

  const renderActions = () => {
    return (
      <Flex justify="flex-end" gap="xs">
        {areThereDuplicatedFiles && !newFiles?.length && !filesThatNeedUpdate?.length ? (
          <>
            <Button variant="subtle" color="gray.7" onClick={onClose}>
              Close
            </Button>
          </>
        ) : (
          <>
            <Button variant="subtle" color="gray.7" onClick={onClose} disabled={isUpdatingFileMetadata}>
              Cancel
            </Button>

            <Button
              onClick={handleUpload}
              disabled={!selectedFiles.length || isCheckingUploadFiles}
              loading={isUpdatingFileMetadata}
            >
              {duplicatedFileAssets?.length === selectedFiles.length ? 'Confirm' : 'Upload'}
            </Button>
          </>
        )}
      </Flex>
    );
  };

  return (
    <Modal size={1000} opened={opened} onClose={onClose} title="Upload Files" centered>
      <Flex direction="column" gap="sm" sx={{ position: 'relative' }}>
        <Flex sx={{ height: 160, position: 'relative' }}>
          <FileDropzone
            visible
            accept={ALLOWED_FILE_TYPES}
            description={
              selectedFiles.length > 0 ? `You have selected ${selectedFiles.length} file(s)` : defaultDescription
            }
            subDescription="Supported format: *.pdf. The size should not exceed 1GB."
            onUpload={handleSelectFiles}
            loading={isCheckingUploadFiles || isUpdatingFileMetadata}
            multiple
            rejectable={rejectable}
          />
        </Flex>

        {areThereDuplicatedFiles && (
          <DuplicatedFilesAlert
            duplicatedFileAssets={duplicatedFileAssets}
            newFileAssets={newFileAssets}
            totalSelectedFiles={selectedFiles.length}
          />
        )}

        {areThereNewFiles && (
          <Checkbox
            checked={automaticallyGenerateSummaries}
            onChange={(event) => setAutomaticallyGenerateSummaries(event.target.checked)}
            label="Automatically generate summaries after the upload process is completed."
          />
        )}

        {renderActions()}
      </Flex>
    </Modal>
  );
};

export const UploadFileModal = memo(UploadFileModalBase);
