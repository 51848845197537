import { useMutation, useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';

import { useChatServerRequest } from '@/shared/hooks/request/useChatServerRequest';
import { ChatHistoryQuery, queryMap as chatHistoryQueryMap, ChatMessage, ChatMessageRole } from '../useChatHistory';

interface SendChatResponseBody {
  session_id: string;
  bot_message: ChatMessage;
}

interface ChatRequestBody {
  user_id: string;
  org_id: string;
  client_id: string;
  user_message: string;
  session_id?: string;
}

export const useSendChat = () => {
  const { request } = useChatServerRequest();
  const queryClient = useQueryClient();

  const mutation = useMutation<SendChatResponseBody, Error, ChatRequestBody>({
    mutationFn: async (chatRequest) => {
      queryClient.setQueriesData(
        chatHistoryQueryMap.chatHistory.queryKey(chatRequest.user_id, chatRequest.org_id, chatRequest.client_id),
        (oldData: ChatHistoryQuery | undefined) => {
          return {
            ...oldData,
            session_id: oldData?.session_id || uuidv4(),
            messages: [
              ...(oldData?.messages || []),
              {
                id: uuidv4(),
                role: ChatMessageRole.Human,
                message: chatRequest.user_message,
              },
            ],
          };
        },
      );

      const response = await request('/api/v1/chat', {
        method: 'POST',
        body: JSON.stringify(chatRequest),
      });

      if (!response.ok) {
        throw new Error('Failed to send chat message');
      }

      return response.json();
    },
    onSuccess: (responseBody, variables) => {
      // Update chat history query with the new message
      queryClient.setQueryData(
        chatHistoryQueryMap.chatHistory.queryKey(variables.user_id, variables.org_id, variables.client_id),
        (oldData: ChatHistoryQuery | undefined) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            messages: [...oldData.messages, responseBody.bot_message],
          };
        },
      );
    },
  });

  return {
    sendMessage: mutation.mutate,
    sendMessageAsync: mutation.mutateAsync,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
  };
};
